.c-list{
	display:flex;
	justify-content: space-around;
	.content{
		border-width:0 6px 0 6px;
		border-style:solid;
		border-color:$color2;
		box-shadow: 0 0 12px rgba(53,53,53,0.15);
		background:#fff;
		margin:-40px auto 0;
		padding:25px 20px;
		position:relative;
		width:400px;
		z-index: 10;
		@include sp{
			width:90%;
			margin:-20px auto 0;
			padding: 15px 10px;
		}
	}
	.thumb{
		width:450px;
		border-radius: 5px;
		overflow: hidden;
		position: relative;
		z-index: 1;
		@include sp{
			width:auto;
		}
	}

	.job{
		line-height: 21px;
		font-weight: 500;
	}

	small{
		@include f-size(12);
		display:block;
	}

	.ja{
		@include f-size(21);
		@include sp{
			@include f-size(13);
		}
	}

	.en{
		@include f-size(12);
		color:$color2;
		margin-left: 20px;
		vertical-align: 5px;
		@include sp{
			margin-left:10px;
			@include f-size(12);
			vertical-align: 0px;
		}
	}
	.item{
		@include breakpoint(1150px) {
			width: 50%;
		}
		@include sp {
			width: 100%;
		}
		&:last-child{
			@include breakpoint(1000px) {
				display:none;
			}
		}
	}

}

.c-list2{
	display:flex;
	flex-wrap:wrap;
	@include breakpoint(1200px) {
		justify-content: space-between;
	}
	.thumb{
		border:3px solid $color4;
		border-radius: 5px;
		overflow: hidden;
		box-shadow: 0 0 12px rgba(35,35,35,0.15);
	}
	.item{
		width:300px;
		@include breakpoint(1200px) {
			width: 40%;
		}
		&:nth-child(3n-1){
			margin:35px 100px 0;
			@include breakpoint(1200px) {
				margin:0 0;
			}
		}
		@include breakpoint(1200px) {
			width:48%;
			margin-bottom: 10px;
			&:last-child, &:nth-last-child(2){
				display:none;
			}
		}
	}
	p{
		line-height: 25px;
		margin-top:25px;
		@include breakpoint(1200px) {
			margin-top:10px;
			line-height: inherit;
		}
	}
}

.c-list3{
	a{
		border-left:5px solid $color2;
		background:#fff;
		display:inline-block;
		width:230px;
		max-width:230px;
		height:60px;
		line-height: 60px;
		text-align: center;
		box-shadow: 0 0 12px rgba(35, 35, 35, 0.15);
		margin-right:23px;
		margin-bottom:5px;
		overflow: hidden;
		box-sizing: border-box;
		&:nth-child(4n){
			margin-right:0px;
		}
		&:hover{
			background:#f5f5f5;
		}
	}
	@include breakpoint(1200px){
		display:flex;
		flex-wrap:wrap;
		justify-content: space-between;
		a{
			width:47%;
			max-width:100%;
			margin-right:0px;
			height: 40px;
			line-height: 40px;
		}
	}
}

.c-list4{
	.item{
		position:relative;
		a{
			border-bottom:2px solid #d5d5d5;
			padding: 22px 40px 19px 8px;
			display:block;
			@include sp{
				border-bottom:none;
				padding:15px 0;
				&:after{
					width:200%;
					height:1px;
					background:#d5d5d5;
					content:'';
					display:block;
					position:absolute;
					bottom:0px;
					left:-30px;
				}
			}
		}
		time{
			@include f-size(14);
			margin-right:28px;
			font-weight: 400;
			@include sp{
				@include f-size(12);
				margin-right:10px;
			}
		}
		.cat{
			display:inline-block;
			min-width:82px;
			padding:0 15px;
			@include f-size(13);
			color:#fff;
			height:25px;
			line-height: 25px;
			text-align: center;
			margin-right:28px;
			border-radius: 3px;
			&.news {
				background-color: #f8cac3;
			}
			&.seminar {
				background-color: #fdcd00;
			}
			@include sp{
				height:20px;
				line-height: 20px;
				@include f-size(12);
				min-width:auto;
				margin-right:0px;
			}
		}
		.title{
			@include breakpoint(1200px) {
				display: inline-block;
			}
			@include sp{
				display:block;
				margin-top:5px;
				padding-right:10px;
				box-sizing: border-box;
			}
		}
		&:after{
			position:absolute;
			content:"\f105";
			font-family: "FontAwesome";
			top:25%;
			right:24px;
			@include f-size(20);
			@include sp{
				right:0px;
				top:unset;
				bottom:9px;
			}
		}
		&:hover{
			background:#f5f5f5;
			transition:all .25s linear;
			&::after{
				right:20px;
				transition:all .25s linear;
				@include sp{
					right:-5px;
				}
			}
			@include sp{
				background: transparent;
			}
		}
	}
}

.c-list5{
	display:flex;
	flex-wrap:wrap;
	margin-right:-45px;
	@include sp{
		margin-right:0px;
		justify-content: center;
	}
	.item{
		width:266px;
		margin-right:45px;
		margin-bottom:80px;
		@include sp{
			width:180px;
			max-width:100%;
			margin-right:20px;
			margin-bottom:30px;
		}
		a {
			display: block;
			&:hover {
				opacity: 0.75;
			}
		}
		.thumb{
			border:3px solid $color2;
			border-radius: 5px;
			overflow: hidden;
		}
		.time{
			@include f-size(14);
			@include sp{
				@include f-size(13);
			}
		}
		.title{
			@include f-size(15);
			font-weight:500;
			@include sp{
				@include f-size(14);
				line-height: 22px;
			}
		}
		.content{
			margin-top:17px;
			@include sp{
				margin-top:10px;
			}
		}
	}
}

.c-list6{
	.item{
		margin-bottom:40px;
		@include sp{
			margin-bottom: 20px;
			box-shadow: 0 0 4px rgba(53,53,53,0.2);
			padding: 20px;
		}
		>a{
			display:flex;
			justify-content: space-between;
			&:hover {
				opacity: 0.75;
			}
			@include sp{
				flex-direction: column;
			}
		}

		.thumb{
			width:300px;
			img{
				border-radius: 10px;
			}
			@include sp{
				max-width:100%;
				margin:0 auto 15px;
			}
		}
		.content{
			width:485px;
			@include sp{
				width:auto;
			}
			.cat{
				margin-right:-15px;
				margin-bottom:10px;
				span{
					@extend .c-btn-cat;
				}
			}

			.title{
				font-weight:bold;
				@include f-size(21);
				margin-bottom:15px;
				line-height: 31px;
				@include sp{
					@include f-size(17);
					line-height: 22px;
					margin-bottom: 10px;
				}
				&:after{
					content:"";
					display:block;
					width:100%;
					height:2px;
					background-color:#dcdcdc;
					margin-top:5px;
				}
			}
		}
	}
}