.bg_News{
	background-image:url('../img/voice/ttlPage_bg.jpg');
	@include sp {
		background-image:url('../img/voice/ttlPage_bg_sp.jpg');
	}
}

.l-voice,
.l-voice-detail  {
	.c-titlePage{
		@extend .bg_News;
	}
}



.l-voice{
	.voice-text {
		text-align: center;
		padding-bottom: 60px;
		@include f-size(15);
		@include sp {
			padding-top: 25px;
			padding-bottom: 35px;
		}
	}
	.voice-content{
		position:relative;
		&::before{
			content: '';
			background: url('../img/voice/twinkle_01.png') no-repeat;
			display: inline-block;
			width: 553px;
			height: 165px;
			position: absolute;
			left: 0px;
			top: -130px;
			@include breakpoint(1350px) {
				display: none;
			}
			@include sp {
				display: none;
			}
		}
		&::after {
			content: '';
			background: url('../img/voice/twinkle_02.png') center top;
			display: inline-block;
			width: 1840px;
			height: 1179px;
			max-width: 100%;
			position: absolute;
			top: 0px;
			z-index: -1;
			@include sp {
				display: none;
			}
		}
		.container2{
			position: relative;
			background:transparent;
			max-width: 1200px;
			width: 100%;
			@include sp {
				margin: 0px;
			}
			&:after{
				content: '';
				display: block;
				background: $color5;
				position: absolute;
				height: 100%;
				max-width: 1000px;
				left: 50%;
				transform: translateX(-50%);
				right: 0;
				top: 0;
				// margin: 0 auto;
				z-index: -1;
				width: 100%;
			}
		}
		.c-list5 {
			display:flex;
			justify-content: space-between;
			flex-wrap: wrap;
			max-width: 1100px;
			margin: 0 auto;
			padding-top: 50px;
			padding-bottom: 100px;
			@include sp {
				width: 100%;
			}
			&::after {
				content: "";
				display:block;
				width: 27.5%;
			}
			a {
				display: block;
				max-width: 300px;
				@include sp {
					margin: 0 auto;
					padding: 0px 20px;
				}
			}
			.item {
				width: 27.5%;
				margin-right: 0px;
				padding-bottom: 30px;
				@include sp {
					width: 100%;
				}

				&:nth-child(3n-1) {
					padding-top: 35px;
					@include sp {
						padding-top: 0px;
					}
				}
				.thumb {
    			margin-bottom: 15px;
   				height: auto;
    			max-width: 295px;
    			width: auto;
    			border: none;
    			box-shadow: none;
    			margin: 0 auto;
    			img {
    				border: 3px solid #fdcd00;
    				border-radius: 5px;
    				overflow: hidden;
    				box-shadow: 0 0 12px rgba(53,53,53,0.15);
    			}
				}
			}
		}
		.c-pagination {
			margin-top: -30px;
		}
	}
}

.l-voice-detail {
	.container2 {
			position: relative;
			background:transparent;
			max-width: 1200px;
			width: 100%;
			text-align: center;
			@include sp {
				margin: 0px;
			}
		}
	.container {
		@include sp {
			padding: 0 15px;
		}
	}
	.voice-mainimg {
		margin-bottom: 20px;
		display: inline-block;
		text-align: center;
		max-width: 1200px;
		width: 100%;
		img {
			box-shadow: 0 0 12px rgba(53,53,53,0.15);
		}
	}
	.voice-d-customer {
		text-align: right;
		@include f-size(18);
		padding-bottom: 55px;
		@include sp {
			@include f-size(14);
			padding-bottom: 30px;
		}
	}
	.voice-d-ttl {
		@include f-size(28);
		font-weight: 300;
		padding-bottom: 35px;
		@include sp {
			@include f-size(18);
		}
	}
	.voice-d-faq {
		dt {
			background-color: $color5;
			border-radius: 7px;
			padding: 5px 15px 0px;
			@include f-size(21);
			margin-bottom: 20px;
			// display:flex;
			// justify-content: space-between;
			// flex-wrap: wrap;
			padding: 15px 25px 7px;
			@include sp {
				@include f-size(17);
				line-height: 1.3;
				padding: 10px;
			}
			span {
				@include f-size(36);
				margin-right: 15px;
				font-family: 'Overpass', sans-serif;
				// display: flex;
				// align-items: center;
				line-height: 1em;
				display: inline-block;
				@include sp {
					@include f-size(26);
					margin-right: 5px;
					line-height: 1.3;
				}
			}
			p {
				width: calc(100% - 60px);
				// display: flex;
				// align-items: center;
				display: inline-block;
				padding-bottom: 10px;
    			vertical-align: middle;
				@include sp {
					width: calc(100% - 30px);
				}
			}
		}
		dd {
			padding-left: 25px;
			display:flex;
			justify-content: space-between;
			flex-wrap: wrap;
			padding-bottom: 40px;
			@include sp {
				padding-left: 0px;
			}
			span {
				@include f-size(36);
				color: #fdcd00;
				line-height: 1;
				font-family: 'Overpass', sans-serif;
				@include sp {
					@include f-size(26);
					padding-left: 10px;
				}
			}
			p {
				@include f-size(17);
				width: calc(100% - 40px);
				line-height: 1.9;
				@include sp {
					@include f-size(14);
				}
			}
		}
	}
	.voice-d-planner {
		border: 3px solid #fdc708;
		padding: 40px;
		display:flex;
		justify-content: space-between;
		flex-wrap: wrap;
		border-radius: 9px;
		margin-bottom: 70px;
		@include sp {
			padding: 15px 15px 20px;
			margin-bottom: 40px;
		}
		.planner-textbox {
			width: 62%;
			@include sp {
				width: 100%;
				padding-bottom: 30px;
			}
			.planner-ttl {
				@include f-size(30);
				position: relative;
				margin-bottom: 39px;
				@include sp {
					@include f-size(18);
					margin-bottom: 29px;
				}
				&::after {
					content: "";
					background-color: #fdc708;
					width: 65px;
					height: 1px;
					display: inline-block;
					position: absolute;
					left: 0px;
					bottom: -10px;

				}
			}
			.planner-text {
				@include f-size(15);
				@include sp {
					@include f-size(15);
				}
			}
		}
		.planner-imgbox {
			width: 33%;
			@include sp {
				width: 100%;
			}
			.planner-img {
				max-width: 302px;
				height: auto;
				text-align: center;
				@include sp {
					margin: 0 auto;
				}
			}
			.planner-name {
				@include f-size(13);
			}
		}
	}
}