.l-faq{
	.c-titlePage{
		background-image: url('../img/faq/ttlPage_bg.jpg');
		@include sp {
			background-image: url('../img/faq/ttlPage_bg_sp.jpg');
		}
	}

	.content{
		position:relative;
		padding-top:55px;
		padding-bottom:110px;
		@include sp{
			padding:20px 0 40px;
		}
	}

	.faq-q, .faq-a {
		position: relative;
	}

	.icon{
		position: absolute;
		left: 0;
		top: 0;
		font-weight: 500;
		line-height: 1;
		@include f-size(36);
		@include font(over);
		@include sp{
			@include f-size(22);
		}
	}
	.faq-q{
	    padding: 20px 85px 21px;
	    counter-increment: faq;
	    background:#fdefed;
	    border-radius: 6px;
	    @include sp{
	    	padding:10px 20px 10px 50px;
	    }
		span{
			left:22px;
			top:22px;
			&:after{
			    content: "." counter(faq);
			}
			@include sp{
				top:12px;
				left:10px;
			}
		}
		p{
			font-weight: 500;
			@include f-size(21);
			padding-top: 2px;
			@include sp{
				@include f-size(12);
			}
		}
	}

	.faq-a{
        padding: 22px 40px 35px 90px;
		font-weight:400;
		@include sp{
			padding:10px 20px 10px 50px;
		}
		>span{
			left:50px;
			top:25px;
			color:#f8cac3;
			@include sp{
				left:28px;
				top:12px;
			}
		}
		p{
			padding-top: 5px;
			span{
				font-weight:500;
				display:block;
				@include f-size(17);
				@include sp{
					@include f-size(13);
				}
			}
		}
	}

		//OTHERS
	.mark01{
		>img{
			position:absolute;
			top:-60px;
			left:0px;
			z-index: 0;
		}
		@include sp{
			display:none;
		}
	}
}