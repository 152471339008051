.c-tab{
	display:flex;
	flex-wrap:wrap;
	justify-content: space-between;
	position: relative;
	li{
		width:300px;
		position:relative;
		@include sp{
			width:100%;
			margin-bottom: 10px;
		}
		a{
			display:block;
			border-width:6px 0;
			border-style: solid;
			border-color: $color2;
			@include f-size(18);
			text-align: center;
			position:relative;
			padding:30px 0;
			box-shadow: 0 0 5px rgba(53,53,53,0.1);
			&:hover {
			 	&:before{
					content:"";
					background:url('../img/others/star_pink.png') no-repeat;
					height:18px;
					width:18px;
					position:absolute;
					top:15px;
					left:calc(50% - 9px);
					display:block;
					background-size:cover;
					transition:0.2s;
					@include sp{
						top:2px;
					}

				}
				&:after{
					content:"";
					display:block;
					position: absolute;
					bottom:-14px;
					left:calc(50% - 14px);
					border-left:14px solid transparent;
					border-right:14px solid transparent;
					border-top:14px solid $color2;
					transition:0.2s;
					@include sp{
						display:none;
					}

				}
			 }
			@include sp{
				@include f-size(15);
				padding:15px 0;
				border-width:3px 0;
			}
		}
	}
}