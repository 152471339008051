.c-title{
	text-align: center;
	@include sp {
		width: 100%;
	}
	.en{
		text-transform: uppercase;
		@include font(over);
		@include f-size(39);
		line-height: 1;
		@include sp{
			@include f-size(22);
		}
	}
	.ja{
		@include f-size(14);
		font-weight: 400;
	}
	&:before{
		content:"";
		display:block;
		background:url('../img/others/star.png');
		background-size:cover;
		height:35px;
		width:35px;
		margin:0 auto 30px;
		@include sp{
			width:20px;
			height:20px;
			margin:0 auto 15px;
		}
	}
	//c-title child Page
	&Page{
		min-height:300px;
		margin-bottom:22px;
		background-size:cover;
		background-repeat: no-repeat;
		background-position: center center;
		position:relative;
		border-radius: 5px;
		.c-title{
			@include center;
			position:absolute;
			&:before{
				display:none;
			}
			.en{
				color:#fff;
			}
			.ja{
				color:#fff;
			}
		}
		@include sp{
			min-height: auto;
			margin-bottom:10px;
			width:100%;
			height:auto;
			background-size:100%;
			display:block;
			padding-top:47%;
		}
	}
}

.c-titlePage--star{
	&:before{
		content: "";
		display: block;
		height: 65px;
		width: 2px;
		background-color: #bfbfbf;
		bottom: -33px;
		left:calc(50% - 1px);
		position: absolute;
		@include sp{
			height:30px;
			bottom:-15px;
			display: none;
		}
	}
	&:after{
		content:"";
		background:url('../img/others/star.png') no-repeat;
		background-size:cover;
		height:35px;
		width:35px;
		display:block;
		position:absolute;
		bottom:-75px;
		left:calc(50% - 17.5px);
		@include sp{
			height:20px;
			width:20px;
			left:calc(50% - 10px);
			bottom:-40px;
			display: none;
		}
	}
}

.c-title2{
	@include f-size(30);
	text-align: left;
	@include sp{
		@include f-size(20);
	}
	&:after{
		content:'';
		height:2px;
		width:64px;
		background:$color2;
		display:block;
		margin:10px 0 0;
		@include sp{
			margin: 0 0;
			width:40px;
			height:2px;
		}
	}
	&--center{
		text-align: center;
		&:after{
			margin:10px auto 0;
		}
	}
	&--yellow{
		&:after{
			background:#feda44;
		}
	}
}


.c-title3{
	@include f-size(21);
	font-weight:500;
	border-bottom: 4px solid #dcdcdc;
	padding-bottom:10px;
	@include sp{
		@include f-size(17);
		border-width:2px;
		padding-bottom: 5px;
	}
}