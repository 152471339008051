
.l-staff,
.l-staff-detail  {
	.c-titlePage{
		background-image:url('../img/staff/ttlPage_bg.png');
		@include sp {
			background-image:url('../img/staff/ttlPage_bg_sp.jpg');
		}
	}
}
.l-staff-detail {
	.c-breadcrumb {
		a {
			&:last-of-type {
				&::after {
					display: none;
				}
			}
		}
	}
}

.l-staff-Box {
	background: url('../img/staff/twinkle_02_bg.png');
	position: relative;
	@include sp {
		background: none;
	}
	&::before {
		content: "";
		display: inline-block;
		background: url('../img/staff/twinkle_01.png') no-repeat;
		width: 600px;
		height: 139px;
		position: absolute;
		top: 65px;
		z-index: -10;
		@include sp {
			display: none;
		}
	}
	.staff-text {
		text-align: center;
		color: #434343;
		padding-bottom: 100px;
		@include sp {
			padding-bottom: 45px;
			padding-top: 15px;
		}
	}

	.staff-flex {
		display:flex;
		justify-content: space-around;
		flex-wrap: wrap;
		padding-bottom: 85px;
		@include sp {
			padding-bottom: 60px;
		}
		&::after {
			content: "";
			width: 45%;
		}
		.item {
			width: 45%;
			@include sp {
				width: 100%;
				&:last-child {
					display: block;
				}
			}
			&:not(:nth-child(-n+2)) {
				padding-top: 100px;
			}
			@include sp {
				&:not(:nth-child(-n+1)) {
					padding-top: 40px;
				}
			}
		}
	}
}

.l-staff-detail {
	.container {
		@include sp {
			padding: 0px 10px;
		}
	}
	.c-list {
		.item {
			padding-bottom: 85px;
			@include sp {
				padding-bottom: 35px;
				&:last-child {
					display: block;
				}
			}
		}
		.thumb {
			max-width: 1200px;
			width: auto;
			img {
				max-height: 370px;
				width: auto;
			}
		}
		.content {
    	margin: -40px 0 0;
		}
	}
	.staff-detail-ttl {
		@include f-size(25);
		display: block;
		border-bottom: 4px solid #ececec;
		padding-bottom: 15px;
		margin-bottom: 35px;
		display:flex;
		justify-content: space-between;
		flex-wrap: wrap;
		@include sp {
			@include f-size(17);
			padding-bottom: 7px;
			margin-bottom: 20px;
		}
		.ttl {
			padding-left: 10px;
			vertical-align: middle;
			@include sp {
				padding-left: 0px;
			}
			&::before {
				content: "";
		    background: url(../img/others/star.png) no-repeat;
		    background-size: cover;
		    height: 35px;
		    width: 35px;
		    display: inline-block;
		   	vertical-align: sub;
		   	margin-right: 5px;
		   	@include sp {
		   		height: 20px;
		    	width: 20px;
		   	}
			}
		}
		.sub {
			@include f-size(15);
			vertical-align: middle;
			padding-top: 17px;
			@include sp {
				padding-top: 5px;
				@include f-size(12);
			}
		}
	}
	.staff-profile {
		padding-bottom: 45px;
		@include sp {
			padding-bottom: 0px;
		}
		.staff-table {
			margin-left: 25px;
			margin-bottom: 30px;
			@include sp {
				margin-left: 0px;
				width: 100%;
			}
			tr {
				@include sp {
					width: 100%;
				}
				th {
					padding: 10px 5px;
					width: 150px;
					@include f-size(15);
					@include sp {
						display: block;
						padding: 5px;
						width: 100%;
						border-bottom: 1px solid #ccc;
					}
				}
				td {
					padding: 10px 5px;
					@include f-size(15);
					font-weight: 400;
					@include sp {
						@include f-size(14);
						display: block;
						padding: 5px 0px 10px 15px;
					}
				}
			}
		}
	}
	.staff-favorite {
		padding-bottom: 45px;
		@include sp {
			padding-bottom: 35px;
		}
		.staff-favorite-list {
			display:flex;
			justify-content: space-between;
			flex-wrap: wrap;
			@include sp {

			}
			&::before {
				content: "";
				width: 30.2%;
				order: 1;
			}
			&::after {
				content: "";
				width: 30.2%;
			}
			li {
				width: 30.2%;
				&:not(:nth-child(-n+3)) {
					padding-top: 45px;
				}
				.img {
					padding-bottom: 5px;
					width: auto;
			    height: auto;
			    max-width: 100%;
			    min-height: 252px;
			    text-align: center;
			    position: relative;
			    img {
			    	max-height: 252px;
			    }
				}
				@include sp {
					max-width: 302px;
					width: 100%;
					margin: 0 auto;
					&:not(:nth-child(-n+1)) {
					padding-top: 30px;
				}
				}
			}
		}
	}
	.staff-message {
		padding-bottom: 20px;
	}
}