.l-blog{
	.c-titlePage{
		background-image: url('../img/blog/ttlPage_bg.jpg');
		@include sp {
			background-image: url('../img/blog/ttlPage_bg_sp.jpg');
		}
	}

	.c-breadcrumb{
		padding-bottom:35px;
		@include sp{
			padding-bottom:10px;
		}
	}
	.blog-content{
		position:relative;
		&.bg_zindex {
			&::after {
				content: '';
		    display: block;
		    position: absolute;
		    height: 100%;
		    max-width: 1000px;
		    left: 50%;
		    -webkit-transform: translateX(-50%);
		    transform: translateX(-50%);
		    right: 0;
		    top: 0;
		    margin: 0px;
		    z-index: -1;
		    width: 100%;
			}
		}
	}
	&.l-blog--list{
		.blog-content{
			 padding-top: 115px;
			 position: relative;
				&::before {
			    content: '';
			    background: url(../img/blog/head_blog.png) no-repeat;
			    display: inline-block;
			    width: 502px;
			    height: 157px;
			    position: absolute;
			    left: 0px;
			    top: -10px;
			    @include sp {
			    	display: none;
			    }
				}
			 @include sp{
			 	padding-top:40px;
			 }
		}
		.blog-content-bg {
			position: relative;
			&::after {
			    content: '';
			    background: url(../img/blog/twinkle_02.png) ;
			    display: inline-block;
			    width: 1920px;
			    height: 1380px;
			    position: absolute;
			    left: 0px;
			    top: -110px;
			    z-index: -5;
			    @include sp {
			    	display: none;
			    }
				}
		}
		.container2{
			position: relative;
			background:transparent;

			.c-pagination{
				margin-top:10px;
				position:relative;
				top:20px;
				margin-bottom: 110px;
				padding-bottom: 0px;
				@include sp {
					margin-bottom: 45px;
				}
			}
		}
		.c-list5 {
			@include flex;
			&::before {
				content: "";
				display: block;
				width: 20.6%;
				margin-right: 45px;
    			margin-bottom: 80px;
				order: 1;
				@include sp {
					margin: 0px;
				}
			}
			&::after {
				content: "";
				display: block;
				width: 20.6%;
				margin-right: 45px;
    			margin-bottom: 80px;
    			@include sp {
					margin: 0px;
				}
			}
			.item {
				width: 20.6%;
				@include sp {
					width: 47%;
					margin-right: 0px;
				}
				a {
					position: relative;
					&:hover {
						opacity: 1;
						.content {
							opacity: 0.75;
						}
						.thumb {
							span {
								display: block;
    						// width: 100%;
    						// height: 100%;
								// &::before {
								// 	content: "";
								// 	display: inline-block;
								// 	opacity: 0.2	;
								// 	background-color: #fff;
								// 	width: 100%;
								// 	height: auto;
								// 	position: absolute;
								// 	top: 0px;
								// 	left: 0px;
								// 	z-index: 1;
								// }
								&::after {
									content: "";
									display: block;
									position: absolute;
									top: 0;
									left:0;
									width: 100%;
									height: 100%;
									background-color: #fff;
									opacity: 0.2;
									pointer-events: none;
								}
							}
						}
						img {
							opacity: 1;
						}
					}
				}
				@include breakpoint(590px) {
					width: 100%;
				}
				.thumb {
					width: 256px;
    			height: 265px;
					border: none;
					position: relative;
					// test
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					span {
						display: inline-block;
						position: relative;
					}
					@include breakpoint(1400px) {
						width: 240px;
						height: 240px;
					}
					@include sp {
						width: 266px;
						height: 266px;
					    text-align: center;
					    margin: 0 auto;
					}
					img {
						border: 3px solid #f8cac3;
						// position: absolute;
						// top: 50%;
						// left: 50%;
						// transform: translate(-50%, -50%);
						max-height: 100%;
						max-width: 100%;

					}
				}
				.content {
					max-width: 264px;
					margin: 0 auto;
				}
			}
		}
	}

	&.l-blog--detail{
		.blog-content {
			position: relative;
			&.content_detail {
				p {
					margin: 20px 0px;
				}
			}
			.container2 {
				@include breakpoint(1400px) {
					width: 90%;
				}
				@include sp {
					margin: 0 auto;
				}
			}
			&::before {
			    content: '';
			    background: url(../img/blog/head_blog.png) no-repeat;
			    display: inline-block;
			    width: 329px;
			    height: 132px;
			    position: absolute;
			    left: 50px;
			    top: 130px;
			    @include breakpoint(1600px) {
			    	display: none;
			    }
			}
			&::after {
			  content: '';
			  background: url(../img/blog/twinkle_03.png) ;
			  display: inline-block;
			  width: 1840px;
			  height: 100%;
			  position: absolute;
			  left: 0px;
			  top: 0px;
			  @include breakpoint(1600px) {
			    display: none;
			  }
			}
		}
		.c-pagination{
			margin-top: 55px;
			margin-bottom: 85px;
			position: relative;
    		z-index: 10;
			@include sp{
				margin: 40px 0;
			}
		}
		.container {
			position: relative;
			z-index: 10;
			@include breakpoint(1145px) {
				padding: 0 45px;
			}

			.wp-post {
				p {
					line-height: 2em;
					@include f-size(15);
				}
				span {
					line-height: 1.5em;
				}
			}
		}

	}

	//OTHERS
	.mark01{
		>img{
			position:absolute;
			top: 15px;
			left: 20px;
			z-index: 10;
		}
		@include sp{
			display:none;
		}
	}

	.mark02{
		>img{
			position:absolute;
			top: 255px;
			right: -95px;
		}
		@include sp{
			display:none;
		}
	}

	.mark03{
		>img{
			position:absolute;
			top: 43%;
			left: 25px;
		}
		@include sp{
			display:none;
		}
	}

	.mark04{
		>img{
			position:absolute;
			bottom: 175px;
			right: 155px;
			;
		}
		@include sp{
			display:none;
		}
	}
}