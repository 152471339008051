.l-column{
	.c-titlePage{
		background-image:url('../img/column/ttlPage_bg.jpg');
		@include sp {
			background-image:url('../img/column/ttlPage_bg_sp.jpg');
		}
	}
	&--list{
		.column-content{
			position: relative;
			&::before {
				content: "";
				background: url('../img/column/twinkle_01.png') no-repeat;
				width: 489px;
				height: 149px;
				display: inline-block;
				position: absolute;
				top: -130px;
				left: 0px;
				z-index: -1;
				@include breakpoint(1440px) {
					display: none;
				}
				@include sp {
					display: none;
				}
			}
		}

		.note{
			text-align: center;
			@include sp{
				margin-top:20px;
			}
		}

		.c-pagination{
			margin-top: 55px;
			margin-bottom: 100px;
			@include sp{
				margin: 40px 0;
			}
		}
		.c-list6 {
			.item {
				@include sp {
					box-shadow: none;
					margin-bottom: 0px;
				}
				.content {
					padding-top: 20px;
					@include sp {
						padding-top: 0px;
					}
				}

			}
		}
		.l-sidebar {
			.l-widget {
				background-color: #fedc4c;
			}
			.c-widget  {
				.rank-list {
					.thumb {
						span {
							background-color: #ff9a8b;
						}
					}
				}
			}
		}
	}
	&--detail{
		.column-content{
			margin-top:0px!important;
		}
		.c-breadcrumb{
			padding-bottom:50px;
			@include sp{
				padding-bottom:40px;
			}
		}
		.l-sidebar {
			.l-widget {
				background-color: #fedc4c;
			}
			.c-widget  {
				.rank-list {
					.thumb {
						span {
							background-color: #ff9a8b;
						}
					}
				}
			}
		}
		.main-content{
			margin-bottom:40px;
			@include sp{
				margin-bottom: 20px;
			}
			.cat{
				margin-bottom: 15px;
			}
			.title{
				@include f-size(28);
				margin-bottom:40px;
				@include sp{
					@include f-size(20);
					margin-bottom: 20px;
				}
				&:after{
					content:"";
					height:3px;
					width:100%;
					background:#dcdcdc;
					display:block;
					margin-top:7px;
					@include sp{
						height:2px;
						margin-top:0px;
					}
				}
			}
			img{
				border-radius: 10px;
				margin-bottom:35px;
				@include sp{
					margin-bottom: 20px;
				}
			}
		}

		.content_box{
			margin-bottom:45px;
			border: 2px solid #fef0b2;
			@include sp{
				margin-bottom: 30px;
			}
			.title {
				background: #fef0b2;
			}
			ul {
				li {
					list-style: none;
					@include sp {
						text-indent: -34px;
						padding-left: 33px;
					}
					a {
						text-decoration: none;
						color: #434343;
						&:hover {
							opacity: 0.75;
						}
					}
				}
			}
		}
		
		.content_Stl01,
		.content_Stl02 {
			margin-bottom:30px;
		}
		.l-content {
			.wp-post  {
				.title.main{
					position: relative;
					@include f-size(21);
					font-weight: 500;
					margin-bottom: 20px;
					background-color: #fff;
					padding: 0px;
					@include sp{
						@include f-size(18);
						margin-bottom: 10px;
					}
					&:before{
						content:"";
						background:url('../img/others/star.png') no-repeat;
						background-size:cover;
						width:22px;
						height:22px;
						display:inline-block;
					    vertical-align: -3px;
					    margin-right:15px;
					    @include sp{
					    	height:18px;
					    	width: 18px;
					    	margin-right: 10px;
					    }
					}
					&:after{
						content:"";
						height:2px;
						width:100%;
						background-color:#dcdcdc;
						display:block;
						margin-top: 7px;
						@include sp{
							margin-top: 0px;
						}
					}
				}
				.title.main {
					position: relative;
					&::after {
						position: absolute;
						bottom: -10px;
						left: 0px;
						@include sp {
							bottom: -5px;
						}
					}
					@include sp {
						text-indent: -34px;
						padding-left: 33px;
						margin-bottom: 20px;
					}
				}
				@include sp{
					margin-bottom: 25px;
				}
				.title {
					text-indent: 0px;
	    		padding: 13px 21px;
	    		border-radius: 5px;
					&::before {
						display: none;
					}
					&::after {
						display: none;
					}
				}
				@include sp{
					margin-bottom: 25px;
				}
			}
		}

		.c-pagination{
			margin-top: 220px;
			margin-bottom: 80px;
			@include sp{
				margin: 40px 0;
			}
		}
	}

	.l-content{
		width:810px;
		@include sp{
			width:auto;
		}
		.wp-post {
			p {
				line-height: 2em;
				@include f-size(15);
			}
		}
		.c-list6 {
			.item {
				&>a {
					@include breakpoint(1380px) {
						justify-content: space-around;
					}
				}
				.thumb {
					@include breakpoint(1380px) {
						width: 33%;
						margin-right: 10px;
					}
					@include sp {
						width: 100%;
						margin-right: 0px;
						text-align: center;
					}
				}
				.content {
					@include breakpoint(1380px) {
						width: 60%;
					}
					@include sp {
						width: 100%;
					}
				}
			}
		}
	}

	.column-content{
		margin-top:80px;
		@include sp{
			margin:40px 0;
		}
		>div{
			display:flex;
			justify-content: space-between;
			@include sp{
				flex-direction:column;
			}
		}
	}
}
.column_temp {
	&.content_box{
		margin-bottom:45px;
		border: 2px solid #fef0b2;
		width: 100%;
		@include sp{
			margin-bottom: 30px;
		}
		.title {
			background: #fef0b2;
			font-weight: 400;
		}
		ul {
			li {
				list-style: none;
				a {
					text-decoration: none;
    			color: #434343;
    			&:hover {
						opacity: 0.75;
					}
				}
				@include sp {
					text-indent: -34px;
					padding-left: 33px;
				}
			}
		}
	}
}
