.c-breadcrumb{
	@include f-size(13);
	text-align: left;
	padding-bottom:60px;
	position:relative;
	@include sp{
		padding-bottom:10px;
		@include f-size(12);
	}
	a{
		&:after{
			content:'＞';
			display:inline-block;
			margin:0 30px;
			@include sp{
				margin:0 7px;
			}
		}
	}
}