
.l-news-list,{
	.c-titlePage{
		background-image:url('../img/news/ttlPage_bg.jpg');
		@include sp {
			background-image:url('../img/news/ttlPage_bg_sp.jpg');
		}
	}
	.news-list{
		margin-top:10px;
		position: relative;
		&::before {
	    content: '';
	    background: url(../img/news/twinkle_01.png) no-repeat;
	    display: inline-block;
	    width: 502px;
	    height: 157px;
	    position: absolute;
	    left: 0px;
	    top: -30px;
	    @include breakpoint(1572px){
	    	display: none;
	    }
	    @include sp {
	    	display: none;
	    }
		}
		&::after {
		    content: '';
		    background: url(../img/news/twinkle_02.png) ;
		    display: inline-block;
		    width: 1920px;
		    height: 818px;
		    position: absolute;
		    left: 0px;
		    top: 130px;
		    z-index: -5;
		    @include sp {
		    	display: none;
		    }
		}
	}
	.c-list4 {
		.item {
			a {
				padding-left: 5px;
			}
			.news {
				background-color: #f8cac3;
			}
			.seminar {
				background-color: #fdcd00;
			}
		}
	}
}

.l-news-detail{
	.c-titlePage{
		background-image:url('../img/news/ttlPage_bg.jpg');
		@include sp {
			background-image:url('../img/news/ttlPage_bg_sp.jpg');
		}
	}
	.c-pagination{
		margin-top: 55px;
		margin-bottom: 85px;
		position: relative;
		z-index: 10;
		@include sp{
			margin: 40px 0;
		}
	}
	.wp-post {
		p {
			line-height: 2em;
			@include f-size(15);
			margin: 0px;
		}
	}

	.container {
		position: relative;
		z-index: 10;
	}

	.c-breadcrumb{
		padding-bottom:20px;
	}
	.news-detail {
		position: relative;
		&::before {
		    content: '';
		    background: url(../img/news/head-news.png) no-repeat;
		    display: inline-block;
		    width: 495px;
    		height: 160px;
		    position: absolute;
		    left: 15px;
		    top: 160px;
		    z-index: 5;
		    @include breakpoint(1750px) {
		    	display: none;
		    }
		}
		&::after {
	    content: '';
	    background: url(../img/news/twinkle_02_d.png) ;
	    display: inline-block;
	    width: 1840px;
    	height: 98%;
	    position: absolute;
	    left: 0px;
	    top: 130px;
	    	@include breakpoint(1750px) {
		    	display: none;
		    }
		}
		.container2 {
			@include breakpoint(1400px) {
				width: 90%;
			}
			.title {
				@include flex(flex-start);
				.time {
					display: flex;
					// width: 50%;
					padding-top: 5px;
				}
				.cat {
				    min-width: 82px;
				    padding: 0 15px;
				    @include f-size(13);
				    color: #fff;
				    height: 25px;
				    line-height: 25px;
				    text-align: center;
				    border-radius: 3px;
				    margin-left: 30px;
				    &.news {
				    	background-color: #f8cac3;
				    }
				    &.seminar {
				    	background-color: #fdcd00;
				    }
				}
				h3 {
					display: block;
					width: 100%;
				}
			}
			@include sp {
				margin: 0 auto;
			}
			.container {
				@include breakpoint(1150px) {
					padding: 0 40px;
				}
				@include sp {
				padding: 0 15px;
				}
			}
		}
	}
}

