@charset "UTF-8";

.c-btn{
	//background:$color1;
	width:200px;
	@include f-size(14);
	color:#fff;
	text-align: center;
	border-radius: 4px;
	height:60px;
	line-height: 60px;border:2px solid $color1;
	display:block;
	&--2{
		@include f-size(18);
		width:300px;
		height:70px;
		line-height: 70px;
	}
	&:hover{
		opacity:0.8;
	}
	@include sp{
		@include f-size(13);
		height:40px;
		line-height:40px;
	}
}

.c-btn2{
	@include f-size(14);
	text-align: center;
	width:250px;
	height:50px;
	line-height: 50px;
	border-radius: 4px;
	position:relative;
	display:block;
	margin:0 auto;
	&:after{
		position:absolute;
		content:"\f105";
		font-family: "FontAwesome";
		top:0;
		right:30px;
		@include f-size(20);
	}
	&:hover::after{
		right:25px;
		transition: right .3s ease;
	}
	@include sp{
		@include f-size(13);
		height:35px;
		line-height: 35px;
		max-width:150px;
		width:100%;
		&:after{
			@include f-size(15);
		}
	}
	&--change{
		width:180px;
		height:30px;
		line-height: 30px;
		@include f-size(13);
		border-radius: 15px;
		background:$color2;
		&:after{
			right:10px;
			@include f-size(15);

		}
		&:hover::after{
			right:7px;
		}
	}
	&--afterChange{
		background-color:$commonColor;
		color:white;
		&:after{
			content:"\f104";
			right:unset;
			left:30px;
		}
		&:hover::after{
			right:unset;
			left:25px;
		}
	}
	&--thin{
		width:315px;
		background:$commonColor;
		color:#fff;
		border-radius: 15px;
		height: 30px;
		line-height: 30px;
		&:after{
			right:10px;
		}
		&:hover::after{
			right:5px;
		}
	}
	&--small{
		width:180px;
		height:30px;
		@include f-size(13);
		line-height: 30px;
		border-radius: 15px;
		background:$commonColor;
		color:#fff;
		&:after{
			right:10px;
		}
		&:hover::after{
			right:7px;
		}
	}
}

.c-btn3{
	width:375px;
	background:#434343;
	color:#fff;
	@include f-size(14);
	text-align: center;
	height:50px;
	line-height: 50px;
	border-radius: 4px;
	display:block;
	@include sp{
		font-size:1.3rem;
		height:40px;
		line-height: 40px;
		max-width:300px;
		width:100%;
	}
	i{
		margin-left:12px;
	}
	&:hover{
		opacity: 0.8;
	}
	&--small{
		width:250px;
		display:inline-block;
		@include sp {
			width: 160px;
		}
	}
	&--white{
		background:#fff;
		border:1px solid $commonColor;
		color:$commonColor;
		&:hover{
			background:$commonColor;
			color:#fff;
			transition:0.2s;
		}
	}
}


.c-btn-cat{
	min-width:100px;
	text-align: center;
	display:inline-block;
	border-left:5px solid $color2;
	box-shadow:0px 0px 4px rgba(53,53,53,0.2);
	margin-right:15px;
	font-size:1.3rem;
	padding: 6px 5px;
	margin-bottom:5px;
	@include sp{
		min-width:90px;
		margin-right:10px;
		padding: 3px 5px;
	}
}

