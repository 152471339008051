.l-life{
	.c-titlePage{
		background-image:url('../img/life/ttlPage_bg.jpg');
		@include sp {
			background-image:url('../img/life/ttlPage_bg_sp.jpg');
		}
	}
	&--list{
		.note{
			text-align: center;
			@include sp{
				margin-top:20px;
			}
		}

		.c-pagination{
			margin-top: 55px;
			margin-bottom: 100px;
			@include sp{
				margin: 40px 0;
			}
		}

		.life-content{
			>div{
				@include sp{
					margin:0 10px;
				}
			}
		}
	}
	&--detail{
		.life-content{
			margin-top:0px!important;
		}
		.c-breadcrumb{
			padding-bottom:50px;
			@include sp{
				padding-bottom:40px;
			}
		}
		.main-content{
			margin-bottom:40px;
			@include sp{
				margin-bottom: 20px;
			}
			.cat{
				margin-bottom: 15px;
			}
			.title{
				@include f-size(28);
				margin-bottom:40px;
				@include sp{
					@include f-size(20);
					margin-bottom: 20px;
				}
				&:after{
					content:"";
					height:3px;
					width:100%;
					background:#dcdcdc;
					display:block;
					margin-top:7px;
					@include sp{
						height:2px;
						margin-top:0px;
					}
				}
			}
			img{
				border-radius: 10px;
				margin-bottom:35px;
				@include sp{
					margin-bottom: 20px;
				}
			}
		}

		.content_box{
			margin-bottom:45px;
			.title {
				font-weight: 400;
			}
			li {
				list-style: none;
				a {
					text-decoration: none;
					color: #434343;
					&:hover {
						opacity: 0.75;
					}
				}
			}
			@include sp{
				margin-bottom: 30px;
			}
		}

		.content_Stl01,{
			padding-bottom: 60px;
			margin-bottom:30px;
			.title.main{
				@include f-size(21);
				font-weight: 500;
				margin-bottom: 20px;
				background-color: #fff;
				@include sp{
					@include f-size(18);
					margin-bottom: 10px;
				}
				&:before{
					content:"";
					background:url('../img/others/star.png') no-repeat;
					background-size:cover;
					width:22px;
					height:22px;
					display:inline-block;
				    vertical-align: -3px;
				    margin-right:15px;
				    @include sp{
				    	height:18px;
				    	width: 18px;
				    	margin-right: 10px;
				    }
				}
				&:after{
					content:"";
					height:2px;
					width:100%;
					background-color:#dcdcdc;
					display:block;
					margin-top: 7px;
					@include sp{
						margin-top: 0px;
					}
				}
			}
			@include sp{
				margin-bottom: 25px;
			}
		}
		.content_Stl02{
			margin-bottom:30px;
			.title {
				text-indent: 0px;
    			padding: 13px 21px;
    			border-radius: 5px;
				&::before {
					display: none;
				}
				&::after {
					display: none;
				}
			}
			@include sp{
				margin-bottom: 25px;
			}
		}

		.c-pagination{
			margin-top: 160px;
			margin-bottom: 80px;
			@include sp{
				margin: 40px 0;
			}
		}
	}

	.l-content{
		width:810px;
		@include breakpoint(1380px) {
			width: 70%;
		}
		.wp-post {
			p {
				line-height: 2em;
				@include f-size(15);
			}
		}
		@include sp{
			width:auto;
		}
		.c-list6 {
			.item {
				&>a {
					@include breakpoint(1380px) {
						justify-content: space-around;
					}
				}
				.thumb {
					@include breakpoint(1380px) {
						width: 33%;
						margin-right: 10px;
					}
					@include sp {
						width: 100%;
						margin-right: 0px;
						text-align: center;
					}
				}
				.content {
					@include breakpoint(1380px) {
						width: 60%;
					}
					@include sp {
						width: 100%;
					}
					.cat {
						span {
						   @include sp {
						   	 min-width: 83px;
						   }
						}
					}
				}
			}
		}
	}

	.life-content{
		margin-top:80px;
		@include sp{
			margin:40px 0;
		}
		>div{
			display:flex;
			justify-content: space-between;
			@include sp{
				flex-direction:column;
			}
		}
	}
}