.c-box{
	max-width:1100px;
	margin:0 auto;
	position:relative;
	left:50px;
	@include sp{
		left:0px;
	}
	.text{
		max-width:650px;
		position:absolute;
		top:90px;
		background:#fff;
		padding:35px 85px 0 0;
		@include sp{
			position:relative;
			padding: 0 0;
			top:0px;
		}
		.quote{
			@include f-size(21);
			display: block;
			margin-top: 45px;
			margin-bottom: 25px;
			@include sp{
				@include f-size(15);
				margin:20px 0 10px;
			}
		}
		.signature{
			line-height: 25px;
			text-align: right;
			margin-top:25px;
			@include sp{
				margin-top:10px;
				line-height: 22px;
			}
		}
	}
	.img{
		text-align: right;
		img{
			border-radius: 5px;
		}
		@include sp{
			text-align: center;
		}
	}
	&--2{
		left:-50px;
		@include sp{
			left:0px;
		}
		.text{
			padding:75px 0 0 85px;
			right:0;
			@include sp{
				padding:0 0;
			}
			.quote{
			    margin-top: 40px;
			    margin-bottom: 22px;
			    @include sp{
			    	margin:20px 0;
			    	width:250px;
			    }
			}
		}
		.img{
			text-align: left;
		}
	}
}