.l-sidebar{
	width:330px;
	padding-bottom: 60px;
	@include breakpoint(1380px) {
		width: 300px;
	}
	@include sp{
		width:auto;
		padding-bottom: 20px;
	}
	.l-widget{
		background:$color2;
		border-radius: 5px;
		padding:30px;
		@include breakpoint(1380px) {
			padding: 20px;
		}
		@include sp{
			padding:20px;
		}
	}

	.l-links{
		margin-top:40px;
		a{
			margin-bottom: 15px;
			display:block;
		}
		@include sp{
			margin-top:20px;
		}
	}
	.c-widget{
		margin-bottom:10px;
		.wg-title{
			display:block;
			@include font(over);
			color:#fff;
			@include f-size(28);
			&:after{
				width:100%;
				height:1px;
				background-color:#fff;
				content:"";
				display:block;
				margin-top:-5px;
			}
			@include sp{
				@include f-size(22);
			}
		}

		.cat-list{
			margin-right:-8px;
			margin-top:20px;
			@include sp{
				text-align: center;
				margin-bottom:10px;
			}
			a{
				min-width:130px;
				background:#fff;
				@include f-size(13);
			    margin-right: 7px;
				margin-bottom:10px;
				display:inline-block;
				text-align: center;
				padding:9px;
				box-sizing: border-box;
				@include breakpoint(1380px) {
					min-width:110px;
				}
				@include sp{
					min-width:110px;
					margin-right:0px;
					padding:5px;
					margin: 5px;
				}
				&:hover{
					opacity:0.8;
				}
			}
		}

		.rank-list{
			margin-top:20px;
			li{
				margin-bottom:10px;
				a{
					display:flex;
					justify-content: space-between;
					&:hover {
						opacity: 0.75;
					}
					.thumb {
						img {
							&:hover {
								opacity: 1;
							}
						}
					}
				}
				.thumb{
					position:relative;
					width:120px;
					@include sp{
						width:48.5%;
					}
					span{
						position:absolute;
						top:0;
						left:0;
						@include font(over);
						background:$color4;
						width:25px;
						height:30px;
						line-height: 30px;
						text-align: center;
						color:#fff;
						font-style: italic;
						z-index: 1;
					}
				}
				.content{
					width:140px;
					@include breakpoint(1380px) {
						width:120px;
					}
					@include sp{
						width:48.5%;
					}
					p{
						@include f-size(12);
						line-height: 20px;
					}
				}
			}
		}
	}
}