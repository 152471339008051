.l-insurance{
	.c-titlePage{
		background-image:url('../img/insurance/ttlPage_bg.jpg');
		@include sp {
			background-image: url('../img/insurance/ttlPage_bg_sp.jpg');
		}
	}

	.container{
		margin-bottom:160px;
		>p{
			text-align: center;
		    margin-bottom: 60px;
		    @include sp{
		    	margin-bottom:30px;
		    }
		}
		@include sp{
			margin-bottom:70px;
		}
	}

	.insurance-company{
		margin-bottom:40px;
		@include sp{
			margin-bottom: 30px;
		}
		>div{
			margin-top:40px;
			margin-right:-20px;
			@include sp{
				justify-content: center;
				margin-top:20px;
			}
			span{
				width:320px;
				height:80px;
				position:relative;
				border:2px solid #eee;
				margin-right:20px;
				margin-bottom:15px;
				@include sp{
					width:280px;
				}
			}
			img{
				position:absolute;
				@include center;
				max-height: 57px;
				max-width: 290px;
				@include sp {
					max-width: 250px;
				}
			}
		}
	}
}