.c-form{
	width:100%;
	border:1px solid #d7d7d7;
	border-collapse: collapse;
	.error {
		background: transparent;
	    @include f-size(12);
	    padding: 3px 3px 3px 7px;
	    display: inline-block!important;
	}
	tr{
		border:1px solid #d7d7d7;
		td{
			border:1px solid #d7d7d7;
			vertical-align: top;
			padding:12px 0;
			&:first-child{
				padding-left:35px;
				width:225px;
				background:#fdefed;
				vertical-align: middle;
				&.last {
					vertical-align: top;
				}
				@include sp{
					width:100%;
					display: block;
					padding-left:15px;
					padding-right:15px;
				}
			}
			&:nth-child(2){
				padding-left:35px;
				font-weight: 400;
				padding-right:35px;
				vertical-align: middle;
				@include sp{
					width:100%;
					display: block;
					padding-left:15px;
					padding-right:15px;
				}
			}
			&.required{
				&:after{
					content:"必須";
					@include f-size(13);
					color:#fff;
					background:#e60012;
					width:40px;
					height:20px;
					line-height: 20px;
					display:inline-block;
					text-align: center;
					border-radius: 3px;
					margin-left: 8px;
					@include sp{
						display:inline-block;
					}
				}
			}
		}
	}

#message{
	width:100%;
	min-height:270px;
	padding:10px;
	@include f-size(15);
	font-family: $noto;
	@include sp{
		width:100%;
		min-height:180px;
		padding:5px;
		@include f-size(16);
	}
}
.lbl-reservation {
	@include f-size(18);
}

.lbl-kurashiki {
	vertical-align: middle;
}

.lbl-consultation {
	@include f-size(15);
	padding: 5px;
	border: 1px solid #d7d7d7;
  border-radius: 3px;
      font-family: "Noto Sans Japanese","ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;
}

.lbl-yonago {
	margin-left:25px;
		vertical-align: middle;
	@include sp{
		margin-left:0px !important;
		margin-right:5px;
	}
}

.lbl-email{
	margin-left:20px;
	@include sp{
		margin-left:0px!important;
	}
}

.lbl-name{
	margin-left:25px;
	@include sp{
		margin-left:0px !important;
		margin-right:5px;
	}
}
.lbl-surname{
	@include sp{
		margin-right:5px;
	}
}
.name{
	width:120px;
	margin-left:10px;
	@include sp{
		width: 100%;
		margin-left:0px;
		margin-bottom:5px;
	}
}

.tel{
	width:70px;
	@include sp{
		max-width:60px;
		width: 100%;
	}
}

.email{
	width:300px;
	@include sp{
		width:100%;
	}
}
.num {
	width:70px;
	margin-right: 10px;
	@include sp{
		max-width:47px;
		width: 100%;
	}
}
.request-list {
	@include flex(flex-start);
	padding-bottom: 20px;
	width: 600px;
	@include sp {
		width: 100%;
	}
	li {
		width: 50%;
		padding: 5px 0px;
		input[type=checkbox] {
			margin-right: 5px;
		}
		@include sp {
			width: 100%;
		}
	}
}

}

input[type=text], input[type=email], textarea{
	border:1px solid #d7d7d7;
	border-radius: 3px;
	@include f-size(15);
	&:focus{
		box-shadow: 0 0 7px #52a8ec;
		border: 1px solid #52a8ec;
	}
	@include sp{
		@include f-size(16);
	}
}

input[type=text], input[type=email]{
	padding:5px 5px 5px 10px;
}
