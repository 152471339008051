.content_detail{
	padding-top: 60px;
	padding-bottom: 50px;
	position: relative;
	@include sp{
		padding:30px 0;
	} 
	.container2{
		padding:60px 0 40px 0;
		@include sp{
			padding: 20px 0;
		}

	}
	video {
		height: auto;
	}
	.container{
		padding:0 100px;
		@include sp{
			padding:0 25px;
		}
	}
	.content{
		img{
			border-radius: 5px;
		}
	}
	.title{
		border-bottom:4px solid #dcdcdc;
		padding-bottom:5px;
		margin-bottom:40px;
		@include sp{
			margin-bottom:20px;
			border-width:2px;
		}
		.time{
			@include f-size(14);
			display:block;
			line-height: 1;
			@include sp{
				@include f-size(13);
			}
		}
	}
	h3{
		@include f-size(25);
		font-weight: bold;
		margin-top: 15px;
		@include sp{
			@include f-size(17);
			margin-top:5px;
		}
	}
	p{
		margin:45px 0;
		@include sp{
			margin:20px 0;
		}
	}


		//OTHERS
		.mark01{
			>img{
				position:absolute;
				top: 120px;
				left: 30px;
				z-index: 10;
			}
			@include sp{
				display:none;
			}
		}

		.mark02{
			>img{
				position:absolute;
				top: 370px;
				right: 145px;
				z-index: 10;
			}
			@include sp{
				display:none;
			}
		}

		.mark03{
			>img{
				position:absolute;
				top: 47%;
				left: 40px;
			}
			@include sp{
				display:none;
			}
		}

		.mark04{
			>img{
				position:absolute;
				bottom: 105px;
				right: 140px;
			}
			@include sp{
				display:none;
			}
		}
	}


.content_box{
	border-radius: 5px;
	border:2px solid #f8cac3;
	overflow: hidden;
	.title{
		background:#f8cac3;
		@include f-size(18);
		text-align: center;
	    padding: 8px 0;
	    @include sp{
	    	@include f-size(17);
	    	padding:5px 0;
	    }
	}	
	ul{
		padding: 22px 27px 18px;
		@include sp{
			padding:15px 20px;
		}
		li{
			line-height: 40px;
			@include sp{
				line-height: 30px;
			}
			&:before{
				content:"";
				background:url('../img/others/star.png') no-repeat;
				background-size:cover;
				width:22px;
				height:22px;
				display:inline-block;
			    vertical-align: -3px;
			    margin-right:15px;
			    @include sp{
			    	width:18px;
			    	height:18px;
			    }
			}
		}
	}
}

.content_Stl01{
	.title.main{
		@include f-size(21);
		font-weight: 500;
		margin-bottom: 20px;
		background-color: #fff;
		@include sp{
			@include f-size(18);
			margin-bottom: 10px;
		}
		&:before{
			content:"";
			background:url('../img/others/star.png') no-repeat;
			background-size:cover;
			width:22px;
			height:22px;
			display:inline-block;
		    vertical-align: -3px;
		    margin-right:15px;
		    @include sp{
		    	height:18px;
		    	width: 18px;
		    	margin-right: 10px;
		    }
		}
		&:after{
			content:"";
			height:2px;
			width:100%;
			background-color:#dcdcdc;
			display:block;
			margin-top: 7px;
			@include sp{
				margin-top: 0px;
			}
		}
	}
}

.content_Stl02{
	.title{
		@include f-size(18);
		font-weight: 500;
	    padding: 9px 25px;
		background:#eeeeee;
		margin-bottom:20px;
		&.main {
			padding: 0px;
		}
		@include sp{
			@include f-size(17);
			padding:5px 25px;
		}
	}
}
