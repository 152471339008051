.c-pagination{
	text-align: center;
	margin-top: 50px;
	padding-bottom: 110px;
	@include sp{
		margin-top: 20px;
		padding-bottom:40px;
	}
	a{
		transition: 0.2s;
	}
	i{
		@include f-size(20);
		@include sp{
			@include f-size(15);
		}
	}
	.page{
		color: #fff;
		background-color: #434343;
		&:hover{
			background-color: $color4;
			color: #434343;
		}
	}
	.current{
		background-color: $color4;
		color: #434343;
		
	}
	.previouspostslink, .nextpostslink{
		background-color:#dcdcdc;
		color:#434343;
	}
	.page, .current, .previouspostslink, .nextpostslink{
		width: 40px;
		height: 50px;
		border-radius: 4px;
		display: inline-block;
		@include f-size(14);
		text-align: center;
		line-height: 50px;
		margin: 0 5px;
		position: relative;
    	vertical-align: middle;
		&::before {
			display: inline-block;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			font-family: "FontAwesome";
			@include f-size(24);

		}
		@include sp{
			width: 33px;
			height: 40px;
			line-height: 40px;
			@include f-size(12);
			margin: 0 2px;
		}
	}
	&--color2{
		.current{
			background-color:$color2!important;
			color:$commonColor;
		}
		.page{
			background-color:#dcdcdc;
			color:$commonColor;
			&:hover{
				background-color:$color2;
			}
		}
		.previouspostslink, .nextpostslink{
			background-color:$commonColor;
			color:#fff;
		}
	}
	.nextpostslink {
		&::before {
			content: "\f105";
		}
	}
	.previouspostslink {
		&::before {
			content: "\f104";
		}
	}
}