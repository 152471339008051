@charset "UTF-8";
@import url(https://fonts.googleapis.com/earlyaccess/notosansjapanese.css);
@import url(https://fonts.googleapis.com/css?family=Montserrat:600,700);
@import url(https://fonts.googleapis.com/css?family=Overpass:400,600);

/*********************************************
DEFAULT
*********************************************/
*,
*::before,
*::after {
  box-sizing: border-box;
}
body{
  font-family: "Noto Sans Japanese","ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;
  -webkit-text-size-adjust: 100%;
  @include f-size($baseFont);
  font-weight:500;
  color:$commonColor;
}

.button{
  font-family:$noto;
}

p {
  line-height:30px;
  font-weight: 400;
  @include sp{
    line-height: 22px;
  }
}
.serif { /* 本文用 */
  font-family: "Times New Roman", "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "メイリオ", Meiryo, serif;
}
.serifH { /* 見出し用 */
  font-family: "Times New Roman", "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "メイリオ", Meiryo, serif;
}
a img {
  transition-property: opacity;
  transition-duration: .2s;
  backface-visibility: hidden;
}
a:hover {
  text-decoration: underline;
}
a:hover img {
  opacity: 0.75;
  -moz-opacity:0.75;
  filter: alpha(opacity=75);
}
iframe[name=google_conversion_frame] {
  position: absolute;
  top: 0;
}
/* iOSでのデフォルトスタイルをリセット */
input[type="submit"],
input[type="button"] {
  border-radius: 0;
  -webkit-box-sizing: content-box;
  -webkit-appearance: button;
  appearance: button;
  border: none;
  box-sizing: border-box;
  cursor: pointer;
}
input[type="submit"]::-webkit-search-decoration,
input[type="button"]::-webkit-search-decoration {
  display: none;
}
input[type="submit"]::focus,
input[type="button"]::focus {
  outline-offset: -2px;
}


/*********************************************
Customize
*********************************************/
html {
  font-size: 10px;
}
body {
  color: $commonColor;
  @include f-size($baseFont);
  line-height: 1.8;
}
a {
  color: $commonColor;
  &:hover{text-decoration: none;}
}
img {
  max-width: 100%;
  height: auto;
}
a[href^=mailto] {
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
}
