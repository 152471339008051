@charset "UTF-8";

@import "font-awesome.min.css";
@import "./_setting";
@import "./_mixin";
@import "./base/_reset";
@import "./base/_body";
@import "./_layout";
@import "./module/_btn";
@import "./module/_title";
@import "./module/_other";
@import "./_state";
@import "./_style";


//Project
@import "project/01_top";
@import "project/02_feeling";
@import "project/03_news";
@import "project/04_contact";
@import "project/05_faq";
@import "project/06_policy";
@import "project/07_insurance";
@import "project/08_blog";
@import "project/09_staff";
@import "project/10_voice";
@import "project/11_column";
@import "project/12_online";
@import "project/90_life";
@import "project/91_store";

//module
@import "module/list";
@import "module/breadcrumb";
@import "module/box";
@import "module/pagination";
@import "module/form";

@import "module/content_detail";//for detail page layout
@import "module/sidebar";
@import "module/tab";
