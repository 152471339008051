@charset "UTF-8";
/*
#overview
layout
*/
html{
-webkit-font-smoothing: antialiased;
}
body {
	-webkit-font-smoothing: subpixel-antialiased;
	// min-width:1400px;
	overflow: hidden;
	@include sp{
		min-width:320px;
		@include f-size(14);
	}
	&#tinymce {
		width: 100%;
		min-width: auto;
	}
}
//CONTAINER
.container{
	width:$baseWidth;
	margin:0 auto;
	text-align: left;
	@include breakpoint(1400px) {
		width: 95%;
	}
	@include sp{
		width:auto;
		padding:0 20px;
	}
}

//CONTAINER2
.container2{
	max-width:1200px;
	margin:0 auto;
	text-align: left;
	background:#fff;
	@include sp{
		width:auto;
		margin:0 20px;
	}
}
//HEADER
.fixed{
	position:fixed!important;
	background:#fff!important;
	z-index: 100;
	width:100%;
	transition: all .2s ease;
 	animation: navAnimate 1s forwards;
 	@keyframes navAnimate {
 		0% {
 			transform: translateY(-40px);
 		}
 		100% {
 			transform: translateY(0px);
 		}
 	} 
	.header-wrapper{
		box-shadow: 0 0 5px rgba(53,53,53,0.2);
		padding: 15px 30px;
		@include sp{
			box-shadow:none;
		}
	}
	#logo{
		width:140px;
		@include sp {
			width: 100px;
		}
	}

	.tel{
		@include f-size(32);
		@include breakpoint(900px){
			display:none;
		}
	}
	.opening-time{
		@include f-size(13);
		@include breakpoint(900px){
			display:none!important;
		}
	}
	.order{
		margin:0 20px;
		@include breakpoint(900px){
			display:none;
		}
	}
	.gNavi-wrapper{
		@include breakpoint(1000px){
			width:100%!important;
		}
	}
}
.l-header{
	// position:fixed;
	background:#fff;
	top: 0;
	z-index: 100;
	width:100%;
	// transition: all .2s ease;
	.header{
		&-wrapper{
			display:flex;
			padding:25px 50px;
			@include sp{
				padding:10px 10px 15px;
				background:#fff;
				position:relative;
				z-index: 101;
				align-items: center;
			}
			#logo{
				width: 180px;
				height: 75px;
				@include sp{
					height: 30px;
					a {
						display:inline-block;
						width:73px;
					}
				}
			}
		}
		&-right{
			margin: 0 95px 0 auto;
			display:flex;
			align-items: center;
			.tel{
				@include font(over);
				@include f-size(39);
				line-height: 1;
				@include breakpoint(1000px){
					display:none;
				}
				@include sp{
					display:none;
				}
			}
			.opening-time{
				font-weight:400;
				@include f-size(14);
				display:block;
				line-height: 1;
				@include breakpoint(1000px){
					display:none!important;
				}
				@include sp{
					display:none;
				}
			}
			.order{
				margin: 0 15px 0 35px;
				@include breakpoint(1000px){
					display:none;
				}
				@include sp{
					display:none;
				}
			}
			#spMenu{
				width:60px;
				height:60px;
				position: fixed;
				right: 75px;
				cursor: pointer;
				z-index:999;
				top: 30px;
				@include sp{
					width:30px;
					height:30px;
					right: 15px;
					top: 10px;
				}
				span{
					background-color: $commonColor;
					width:40px;
					height:3px;
					margin: auto;
					left: 0; right: 0;
					position: absolute;
					display: inline-block;
					transition: 0.2s;
					&:nth-child(1){ top: 5px;}
					&:nth-child(2){	top: 18px; }
					&:nth-child(3){	top: 30px; }
					@include sp{
						width:30px;
						height:2px;
						&:nth-child(1){ top: 5px;}
						&:nth-child(2){	top: 14px; }
						&:nth-child(3){	top: 22px; }
					}
				}
				strong{
					text-align:center;
					position: absolute;
					@include font(over);
					font-weight: bold;
				    bottom: -11px;
					left:0;
					right:0;
					@include sp{
						@include f-size(12);
						bottom: -15px;
						left: -2px;
					}
				}
				&.active{
					span{
						&:nth-child(1){	top: 20px;	transform: rotate(135deg); }
						&:nth-child(2){	opacity: 0; }
						&:nth-child(3){	top: 20px; transform: rotate(-135deg);	}
						@include sp{
						&:nth-child(1){	top: 12px;	transform: rotate(135deg); }
						&:nth-child(3){	top: 12px; transform: rotate(-135deg);	}
						}
					}
				}
			}
		}
	}

	#gNavi{
		display:none;
		position:fixed;
		background:#fff;
		width: 100%;
		z-index: 100;
		padding: 100px 0 200px;
		top: 0px;
		@include sp{
			z-index: 100;
			padding-top:20px;
			padding-bottom:30px;
			box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
			overflow: auto;
			height:calc(100vh - 70px);
		}
		.gNavi-wrapper{
			display:flex;
			justify-content: space-around;
			width:1000px;
			margin:0 auto;
			@include breakpoint(1000px){
				width:100%!important;
			}
			@include sp{
				width:100%;
				flex-direction: column;
			}
		}
		span{
			@include f-size(21);
			border-bottom:1px solid #dcdcdc;
			line-height: 1;
			padding-bottom:20px;
			@include sp{
				@include f-size(14);
				padding-bottom:10px;
				padding-left:30px;
			}
			&:before{
				content:"";
				background:url('../img/others/star.png') no-repeat;
				background-size:cover;
				height:22px;
				width:22px;
				display:inline-block;
				margin-right:7px;
				vertical-align: -3px;
				@include sp{
					vertical-align: 0px;
					height:12px;
					width:12px;
					margin-right:4px;
				}
			}
		}
		.gNavi-col{
			display:flex;
			flex-direction: column;
			ul{
				li{
					line-height: 55px;
					a{
						@include f-size(16);
						position:relative;
						@include sp{
							@include f-size(13);
							display: block;
						}
						&:after{
							content:'';
							display:block;
							position:absolute;
							background:$color4;
							left:0px;
							bottom:-5px;
							width:0%;
							height:2px;
							@include sp{
								display:none;
							}
						}
						&:hover::after{
							width:100%;
							transition:0.2s linear;
   						}
   					}
   				}
   				@include sp{
   					display:flex;
   					flex-wrap:wrap;
   					padding:0 30px;
   					li{
   						line-height: 30px;
   						width:180px;
					    padding: 0 16px;
   						position:relative;
   						@include sp {
   							width: 200px;
   						}
   						&:after{
							position:absolute;
							content:"\f105";
							font-family: "FontAwesome";
							top: 0px;
							right: 0px;
							@include f-size(17);
   						}
   					}
   				}
   			}
   			@include sp{
   				margin-bottom:25px;
   			}
   		}
   		.btn-wrapper{
   			display:flex;
   			justify-content: center;
   			margin-top:50px;
   			a{
   				&:nth-child(1){
   					margin-right:18px;
   					@include sp{
   						margin-left:auto;
   						margin-right:auto;
   						background:#fff;
   						border:2px solid $color1;
   						color:$color1;
   						margin-bottom:6px;
   					}
   				}
   				&:nth-child(2){
   					margin-left:18px;
   					@include sp{
   						margin-left:auto;
   						margin-right:auto;
   						background:$color1;
   						color:#fff;
   						border-color:transparent;
   					}
   				}
   				@include sp{
   					&:nth-child(1), &:nth-child(2){
   						width:100%;
   						max-width:260px;
   					}
   				}
   			}
   			i{
   				@include f-size(24);
   				vertical-align: -2px;
   				color:inherit;
   				margin-right:20px;
   			}
   			@include sp{
   				padding:0 30px;
   				flex-direction:column;
   				text-align: center;
   				margin-top:25px;
   				i{
   					@include f-size(17);
   					vertical-align: 0px;
   					margin-right:12px;
   				}
   			}
   		}
	}
}

//wrapper
#wrapper{
	padding:0 80px;
	overflow: hidden;
	@include sp{
		padding:0 10px;
	}
}

//footer
.l-footer{
	padding:0 80px;
	@include sp{
		padding:0 10px;
	}
	#fixbanner {
	    display: none;
	    position: absolute;
	    top: 50%;
	    transform: translate(0, -50%);
	    position: fixed;
	    right: 0;
	    z-index: 100;
	    .fix02 {
	    	display: none;
	    	@include sp {
	    		display: inline-block;
	    	}
	    }
	    @include sp{
	    	height: 70px;
	    	background-color: #fff;
	    	display: flex;
	    	justify-content: space-between;
	    	align-items: center;
	    	top: auto;
	    	transform: none;
	    	bottom: 0;
	    	left: 0;
	    	padding: 15px 10px;
	    	z-index: 999;
	    }
	    @include sp{
	    	li{
	    		margin:0;
	    		text-align: center;
	    		&.fix01{
    			    width: 41.66%;
	    		}
	    		&.fix02{
    			    width: 41.66%;
	    		}
	    		&.fix03{
    			    width: 12%;
	    		}
	    		img{
	    			width:100%;
	    		}
	    	}
	    }
	}

	.contact{
		padding:65px 0px;
		position:relative;
		background:url('../img/others/twinkle_01_light.png') no-repeat, $color6;
	    background-position: 92% -140px;
	    @include sp{
	    	padding:20px 0;
	    }
		.note{
			text-align: center;
			line-height: 30px;
			@include sp{
				@include f-size(14);
				line-height: 22px;
			}
		}
		&-wrapper{
			background:#fff;
			display:flex;
			justify-content: space-between;
			padding:25px 0px;
			margin:30px 100px 0;
			border-radius: 5px;
			@include breakpoint(1200px) {
				flex-direction: column;
				width: 100%;
    			margin: 0;
			}
			@include sp{
				margin: 30px 0 0;
				padding:15px 0;
				flex-direction: column;
			}
		}
		.via-fone{
			width:50%;
			text-align: center;
			border-right:2px solid #ddd;
			@include breakpoint(1200px) {
				width: 100%;
				border-right: none;
				border-bottom:2px solid #ddd;
				padding-bottom: 10px;
				margin-bottom: 10px;
			}
			span{
				display:block;
				@include f-size(17);
				line-height: 1;
				margin-bottom:15px;
				@include sp{
					@include f-size(14);
					margin-bottom:10px;
				}
			}
			small{
				font-weight: 400;
				display:block;
				@include f-size(12);
				line-height: 1;
			}
			.tel{
				@include font(over);
				@include f-size(39);
				line-height: 1;
				@include sp{
					@include f-size(25);
				}
			}
			@include sp{
				width:100%;
				border-right:none;
				border-bottom:1px solid #ddd;
				padding-bottom:15px;
			}
		}
		.via-web{
			width:50%;
			text-align: center;
			@include breakpoint(1200px) {
				width: 100%;
			}
			span{
				@include f-size(17);
				display:block;
				line-height: 1;
				margin-bottom:15px;
				@include sp{
					@include f-size(14);
					margin-bottom:10px;
				}
			}
			>a{
				margin:0 auto;
				@include sp{
					height:45px;
					line-height: 45px;
					max-width:130px;
					width:100%;
					@include f-size(14);
				}
			}	
			@include sp{
				width:100%;
				padding-top:15px;
			}
		}
	}

	.c-footer{
	    padding: 25px 0;
	    @include sp{
    	    padding: 10px 0 75px;
	    }
		.fNav{
			ul{
				display:flex;
				justify-content: center;
				li{
					margin-right:30px;
					position:relative;
					@include sp {
						text-align: center;
					}
					@include breakpoint(385px) {
						width: 50%;
						margin-right: 0px;
					}
					&:last-child{
						margin-right:0px;
					}
					&:after{
						content:'';
						position:absolute;
						bottom:0px;
						left:0;
						width:0%;
						height:2px;
						background-color: $commonColor;
					}
					&:hover::after{
						width:100%;
						transition:all .25s linear;
						@include sp {
							width: 0;
							transition: none;
						}
					}
					a{
						@include sp{
							@include f-size(13);
						}
					}
				}
			}
		}
		.wrapper{
			display:flex;
			justify-content: space-between;
			margin:50px 0;
			@include breakpoint(1200px){
				flex-direction:column;
				align-items: center;
				margin:22px 0;
			}
			span{
				@include f-size(17);
				&:before{
					background:url('../img/others/star.png');
					content:"";
					height:15px;
					width:15px;
					background-size:cover;
					display:inline-block;
					margin-left: -20px;
					margin-right: 5px;
				}
				@include sp{
					@include f-size(14);
				}
			}
			p{
				@include f-size(14);
				@include sp{
					@include f-size(13);
				}
			}
			.col{
				&:nth-child(1){

					width: 180px;
					@include breakpoint(1200px) {
						padding-bottom: 20px;
					}
					// @include sp{
					// 	width:auto;
					// }
					span{
						display:inline-block;
						@include sp{
							width:105px;
						}
					}
				}
				&:nth-child(2){
					width: 32%;
					border-right:2px solid #e1e1e1;
					@include breakpoint(1200px) {
						width: 300px;
						margin: 0 auto;
						border-right:none;
					}
					@include sp{
					    margin-top: 15px;
						width:auto;
						border-right:none;
					}
				}
				&:nth-child(3){
					width: 29%;
					@include breakpoint(1200px) {
						width: 300px;
						margin: 0 auto;
					}
					@include sp{
					    margin-top: 15px;
						width:auto;
					}
				}
			}
		}

		.copyright{
			text-align: right;
			display:block;
			@include f-size(12);
			line-height: 17px;
			@include sp{
				@include f-size(12);
			}
			@include breakpoint(345px) {
				@include f-size(11);
			}
		}
	}
}
.acf-table {
	.acf-repeater  {
		.acf-row-handle {
			.acf-icon {
				top: 10px;
				&.-minus {
					top: 30px;
				}
			}
		}
	}
}

