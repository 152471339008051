.l-store{
	.c-titlePage{
		background-image:url('../img/store/ttlPage_bg.jpg');
		@include sp {
			background-image:url('../img/store/ttlPage_bg_sp.jpg');

		}
	}

	#wrapper > .container{
		position: relative;
		background:#fff;
	}
	.store-history{
		margin-bottom: 40px;
		position:relative;
		@include sp{
		    margin: 20px 0;
		}
		.quote{
			text-align: center;
			font-weight: 500;
			@include f-size(21);
			line-height: 36px;
			margin-bottom:15px;
			@include sp{
				@include f-size(18);
				line-height: 22px;
				margin-bottom: 10px;
			}
		}
		p{
			text-align: center;
			@include f-size(15);
			position: relative;
			@include sp{
				@include f-size(14);
				line-height: 20px;
				text-align: left;
				padding:  0px 10px;
			}
		}
		.mark-sc{
			position:absolute;
			top: 10px;
			left: 20px;
			@include sp{
				display:none;
			}
		}
	}
	.content{
		margin-top:100px;
		@include sp{
			margin-top:40px;
		}
	}

/* 	.tab-content{
	display:none;
	&.is_current{
		display:block;
	}
} */

	.store-intro{
		padding:90px;
		background:url("../img/store/store_bg.png") no-repeat center -9%,#fdefed;
		position:relative;
		@include sp{
			padding:20px 0;
		}
		.container2{
			@include sp{
				margin:0 10px;
			}
		}
		.c-title2{
			text-align: center;
			position:relative;
			margin-bottom: 75px;
			@include sp{
				margin-bottom: 30px;
			}
			&:after{
				margin: 10px auto 0;
			}
		}
		.mark-si{
			position: absolute;
			top: 55px;
			left: calc(50% - 360px);
			@include sp{
				display:none;
			}
		}
		>div{
			background:transparent;
		}
	}

	.sec-store{
		background:#fff;
		margin-bottom:45px;
		padding:65px 0;
		position:relative;
		@include sp{
			padding: 30px 0;
			margin-bottom: 30px;
		}
		.mark-kurashiki{
			position: absolute;
			bottom: 18%;
			left: -245px;
			@include sp{
				display:none;
			}
		}
		.mark-yonago{
			position: absolute;
			bottom: 18%;
			right: -140px;
			@include sp{
				display:none;
			}
		}
		.title{
			@include f-size(33);
			margin-bottom:50px;
			@include sp{
				@include f-size(22);
				margin-bottom: 25px;
			}
			small{
				@include f-size(25);
				display:inline-block;
				vertical-align: 7px;
				@include sp{
					@include f-size(18);
					vertical-align: 0px;
				}
				&:before{
					content:"";
					background:url('../img/others/star.png') no-repeat;
					background-size:cover;
					width:35px;
					height:35px;
					display:inline-block;
					margin-right: 10px;
					vertical-align: -7px;
					@include sp{
						width:25px;
						height:25px;
						vertical-align: -5px;
					}
				}
			}
			span{
				vertical-align: 3px;
				display:inline-block;
				@include sp{
					vertical-align: 0px;
				}
			}

			&:after{
				content:"";
				background:#dcdcdc;
				width:100%;
				height:4px;
				display: block;
				@include sp{
					height:2px;
				}
			}
		}
		.store-info{
			display:flex;
			justify-content: space-between;
			margin-top:30px;
			margin-bottom: 50px;
			@include sp{
				flex-direction: column;
				margin:20px 0;
			}
			.info{
				max-width:375px;
				@include sp{
					max-width:100%;
				}
				dl{
					display:flex;
					align-items: baseline;
					dt{
						min-width:88px;
						padding:0 5px;
						border-radius: 3px;
						@include f-size(13);
						font-weight:500;
						color:#fff;
						background:$color2;
						text-align: center;
						display:block;
						margin-right:15px;
						display:inline-block;
						vertical-align: baseline;
					}
					dd{
						@include f-size(15);
						font-weight:400;
						line-height:25px;
						margin-bottom:25px;
						display:inline-block;
						@include sp{
							margin-bottom:15px;
							line-height: 22px;
						}
					}
				}

				.c-btn3{
					position: relative;
					margin-top:20px;
					i{
						position:absolute;
						top: 17px;
						right: 32px;
					}
					@include sp{
						margin:10px auto 0;
					}
				}
			}
			.map{
				height: 295px;
				@include sp{
					height: 240px;
					margin-top:20px;
				}
				iframe{
					max-width:600px;
					height: 295px;
					@include breakpoint(1400px) {
						max-width:460px;
					}
					@include sp{
						width:100%;
						height: 240px;
					}
				}
			}
		}
		.store-slwrapper{
			width:665px;
			margin:0 auto 0;
			@include sp{
				width:auto;
			}
			.store-slide1,
			.store-slide2 {
				img{
					border:5px solid #dcdcdc;
					border-radius: 10px;
					max-height:370px;
					@include sp{
						border-width:3px;
					}
				}
				.slick-prev, .slick-next{
					&:hover{
						cursor:pointer;
					}
				}
				.slick-prev{
					background: url('../img/store/prev.png') no-repeat;
					background-size:contain;
					height: 61px;
					width: 31px;
					left: -31px;
					z-index: 2;
					&:before{
						content:"";
					}
					&:hover{
						opacity:0.8;
					}
					@include sp{
						width:21px;
						height:51px;
						left: -21px;

					}
				}
				.slick-next{
					background: url('../img/store/next.png') no-repeat;
					background-size:contain;
					height: 61px;
					width: 31px;
					right: -31px;
					z-index: 2;
					&:before{
						content:"";
					}
					&:hover{
						opacity:0.8;
					}
					@include sp{
						width:21px;
						height:51px;
						right:-21px;
					}
				}
			}
			.store-text1,
			.store-text2 {
				position:relative;
				margin-top:20px;
				padding:0 5px;
				@include sp{
					margin-top:10px;
					span {
						padding-left: 97px;
						display: inline-block;
					}
				}
				p{
					&:before{
						content:"サロンの様子";
						padding-right:22px;
						@include sp{
							padding-right:10px;
							position: absolute;
							top: 0px;
						}
					}
				}
				&:before{
					content:"";
					background:#dcdcdc;
					width:3px;
					height:23px;
					position: absolute;
					display:block;
					left: 105px;
					top: 5px;
					@include sp{
						left: 92px;
						top: 2px;
						width:2px;
					}
				}
			}
		}
	}

	.corp-info{
		padding: 170px 0 110px;
		position:relative;
		@include sp{
		    padding: 30px 0 50px;
		}
		.container{
			@include sp{
				padding:0 0;
			}
		}
		.mark-corp{
			position: absolute;
			top: 30px;
			left: 125px;
			@include sp{
				display:none;
			}
		}
		.mark{
			position: absolute;
			z-index: -1;
			&-01{
				top: -6%;
				right: calc(-50% + 8px);
			}
			&-02{
				top: calc(39% + 5px);
				left: -58%;
			}
			&-03{
				bottom: 11%;
				right: -58%;
			}
			@include sp{
				display:none;
			}
		}
		.corp-wrapper{
			max-width:665px;
			margin:0 auto;
			position: relative;
			.corp-profile{
				margin-bottom: 90px;
				@include sp{
					margin-bottom: 30px;
				}
				.c-title2{
					margin-bottom:35px;
					@include sp{
						margin-bottom: 20px;
					}
				}
			}
			.corp-setup{
				h5{
					@include f-size(19);
					font-weight: 500;
				}
			}
			.corp-table{
				position:relative;
				z-index: 1;
				width:100%;
				background:#fff;
				td{
					border:1px solid #d0d0d0;
					&:first-child{
						background:#fcfcfc;
						vertical-align: middle;
						width:170px;
						padding:0 30px;
						font-weight:500;
						@include sp{
							width:40%;
							padding:0 10px;
						}

					}
					&:last-child{
						padding: 12px 28px;
						vertical-align: middle;
						font-weight: 400;
						line-height: 25px;
						@include sp{
							width:60%;
							padding:5px 10px;
							line-height: 22px;
						}
					}
					ul{
						li{
							.c-btn2{
								margin: 10px 0 35px 15px;
								@include sp{
									margin: 10px auto;
								}
								&:after{
									@include f-size(15);
								}
							}
							&:before{
								content:"■";
							}
							ol{
								padding-left:15px;
								@include sp{
									padding-left:10px;
								}

								>li:before{
									content:"・";
								}
							}
						}
					}
				}
				&--2{
					td{
						&:first-child{
							@include f-size(13);
							font-weight:400;
						}
					}
				}
			}
		}
	}
}