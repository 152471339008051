@charset "UTF-8";

/*******************************
Style Start
*******************************/
.is-pc-hidden {
  display: none;
  @include sp {
    display: inline-block;
  }
}

.is-sp-hidden {
  display: inline-block;
  @include sp {
    display: none;
  }
}

.is-pcb-hidden {
  display: none;
  @include sp {
    display: block;
  }
}

.is-spb-hidden {
  display: block;
  @include sp {
    display: none;
  }
}
