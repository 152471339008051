.l-contact{
	.container {
		padding: 0 10px;
	}
	.c-titlePage{
		background-image: url('../img/contact/ttlPage_bg.jpg');
		@include sp {
			background-image: url('../img/contact/ttlPage_bg_sp.jpg');
		}
	}
	.c-breadcrumb{
		padding-bottom:45px;
		@include sp{
			padding-bottom:20px;
		}
	}
	.step{
		text-align: center;
		margin-bottom: 50px;
		@include sp{
			margin-bottom:20px;
		}
		ul{
			margin:0 auto;
			overflow: hidden;
			width:763px;
			display:flex;
			@include sp{
				width:100%;
				justify-content: center;
			}
			li{
				display:flex;
				text-align: center;
				position: relative;
				color:#b9b9b9;
				padding:25px 0;
				background:#f4f4f4;
				//width:33.33%;
				@include sp{
					align-items: center;
				}
				&:nth-child(1){
					border-radius: 10px 0px 0px 10px;
					padding:25px;
					&:after{
						content: "";
						position: absolute;
						width: 0;
						height: 0;
						top: 0;
						right: -42px;
						border-top: 53px solid transparent;
						border-left: 42px solid #f4f4f4;
						border-bottom: 53px solid transparent;
						z-index: 1;
						@include sp{
							top: 50%;
							margin-top: -40px;
							right: -25px;
							border-top: 40px solid transparent;
							border-left: 25px solid #f4f4f4;
							border-bottom: 40px solid transparent;
						}
					}
					@include sp{
						padding: 10px 17px;
					}
				}
				&:nth-child(2){
					padding:25px 21px;
					margin:0 50px;
					&:before{
						content: "";
						position: absolute;
						width: 0;
						height: 0;
						top: 0px;
						left: -42px;
						border-top: 53px solid #f4f4f4;
						border-left: 42px solid #fff;
						border-bottom: 53px solid #f4f4f4;
						z-index: 0;
						@include sp{
							top: 50%;
							left: -20px;
							margin-top: -40px;
							border-top: 40px solid #f4f4f4;
							border-left: 25px solid #fff;
							border-bottom: 40px solid #f4f4f4;
						}
					}

					&:after{
						content: "";
						position: absolute;
						width: 0;
						height: 0;
						top: 0px;
						right: -42px;
						border-top: 53px solid transparent;
						border-left: 42px solid #f4f4f4;
						border-bottom: 53px solid transparent;
						z-index: 1;
						@include sp{
							top: 50%;
							margin-top: -40px;
							right: -25px;
							border-top: 40px solid transparent;
							border-left: 25px solid #f4f4f4;
							border-bottom: 40px solid transparent;
						}
					}
					@include sp{
						padding:10px 17px;
					    margin: 0 5px 0 25px;
					}
				}
				&:nth-child(3){
					border-radius: 0px 10px 10px 0;
					padding: 25px 77px;
					&:before{
						content: "";
						position: absolute;
						width: 0;
						height: 0;
						top: 0;
						left: -42px;
						border-top: 53px solid #f4f4f4;
						border-left: 42px solid #fff;
						border-bottom: 53px solid #f4f4f4;
						z-index: 0;
						@include sp{
							top: 50%;
							left: 0px;
							margin-top: -40px;
							border-top: 40px solid transparent;
							border-left: 25px solid #fff;
							border-bottom: 40px solid transparent;
						}
					}
					@include sp{
					    padding: 10px 20px 10px 40px;
					}
				}
				p{
					width:100%;
					span{
						display:block;
						@include f-size(16);
						line-height: 1;
						&.is-pc-hidden {
						  display: none;
						  @include sp {
						    display: block;
						  }
						}

						&.is-sp-hidden {
						  display: block;
						  @include sp {
						    display: none;
						  }
						}
						@include sp{
							@include f-size(12);
						}
					}
					i{
						@include f-size(30);
						margin-bottom:10px;
						line-height: 1;
						@include sp{
							@include f-size(17);
							margin-bottom:5px;
						}
					}
				}
				&.current{
					background:$color2;
					color:#2f2f2f;
					&:before{
						border-top-color:#f8cac3;
						border-bottom-color:#f8cac3;
					}
					&:after{
					    border-left-color: #f8cac3;
					}
				}
			}
		}
	}
	h3{
		@include f-size(30);
		border-bottom:4px solid #dcdcdc;
		padding-bottom:10px;
		margin-bottom: 30px;
		font-weight: 500;
		i{
			margin-right:10px;
			vertical-align: middle;
		}
		@include sp{
			@include f-size(20);
			border-width:2px;
			margin-bottom:15px;
			padding-bottom: 5px;
		}
	}
	.c-form{
		margin-top:45px;
		@include sp{
			margin-top:20px;
		}
	}
	.information{
		margin-top: 30px;
		padding: 35px 50px;
		margin-bottom:50px;
		border-radius: 4px;
		background:#f4f4f4;
		span{
			@include f-size(21);
			font-weight: 500;
			@include sp{
				@include f-size(18);
			}
		}
		@include sp{
			margin-top:15px;
			padding:20px 25px;
			margin-bottom:25px;
		}
	}
	.c-btn2{
		margin-bottom:50px;
		@include sp{
			margin-bottom:25px;
			min-width:200px;
		}
		input{
			width:100%;
			height:100%;
			background:transparent;
			color:inherit;
		}
	}
	.confirm-btnWrap{
		display:flex;
		flex-wrap:wrap;
		justify-content: center;
		margin-right:-50px;
		margin:50px -50px 50px 0;
		>input, div{
			margin: 0 50px 0px 0;
			@include sp{
				margin: 0 25px 10px 0;
				min-width: 200px;
			}
		}
		>input {
			&:hover {
				opacity: 0.75;
			}
		}
		>div{
			input{
				height:100%;
				width:100%;
			}
		}
		@include sp{
			margin:25px -20px 25px 0;

		}
	}
}

.l-contact-confirm {
	.form-confirm-none {
		display: none;
	}
	.lbl-surname {
		display: none;
	}
	.lbl-name {
		display: none;
	}
	.lbl-email {
		display: none;
	}
	.information {
		display: none;
	}
	.c-btn2--afterChange {
		border-radius: 5px;
	}
}



.l-contact-completed{
	.message{
		text-align: center;
		margin-top:50px;
		span{
			@include f-size(21);
			display:block;
			margin-bottom:20px;
			@include sp{
				@include f-size(18);
				margin-bottom:0px;
			}
		}
		@include sp{
			margin-top:20px;
		}
	}
	.information{
		display:flex;
		align-items: center;
		@include sp{
			flex-direction: column;
			padding: 10px 25px;
		}
		span{
			width:18%;
			@include sp{
				width:35px;
				margin-bottom: 10px;
			}
		}
	}
}