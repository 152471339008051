.l-policy{
	.rule{
		margin-bottom:50px;
		@include sp{
			margin-bottom:25px;
		}
		p{
			margin-top:30px;
			padding-left:15px;
			position:relative;
			@include sp{
				margin-top:15px;
			}
			span{
				display:block;

				&:before{
					content:"・";
					position:absolute;
					left:0;
				}
			}
		}
	}
}

.l-policy-recruitment{
	.c-titlePage{
		background-image:url('../img/policy/recruitment/ttlPage_bg.jpg');
		@include sp {
			background-image:url('../img/policy/recruitment/ttlPage_bg_sp.jpg');
		}
	}
	.recruitment{
		&-basic{
			padding: 45px 42px 45px 45px;
			border-radius: 10px;
			@include sp{
				padding: 10px 20px;
				display: flex;
				flex-direction: column;
				align-items: center;
			}
			.c-title2{
				margin-bottom: 30px;
				@include sp{
					order: 1;
					margin-bottom: 15px;
					&:after{
						width:100%;
					}
				}

			}
			img{

				float:right;
				margin-top:15px;
				padding-left:50px;
				@include sp{
					order: 2;
					margin-top:0px;
					padding-left:0px;
					margin-bottom: 15px;
				}
			}
			p{
				@include sp{
				    order: 3;
				}

			}
		}

		&-rules{
			margin-top:65px;
			margin-bottom:140px;
			@include sp{
				margin:30px 0 50px 0;
			}
		}
	}
}

.l-policy-privacy{
	.c-titlePage{
		background-image:url('../img/policy/privacy/ttlPage_bg.jpg');
		@include sp {
			background-image:url('../img/policy/privacy/ttlPage_bg_sp.jpg');
		}
	}
	.information{
		background:#eee;
	    padding: 50px 45px 45px 45px;
		margin-bottom:65px;
		border-radius: 5px;
		@include sp{
		    padding: 10px 20px;
		    margin-bottom: 30px;
		}
	}
	.c-btn2{
		margin: 50px 0 0 0;
		@include f-size(13);
		@include sp{
			width:100%;
			max-width:100%;
			margin-top:25px;
		}
	}
	.rules{
		margin-bottom:150px;
		@include sp{
			margin-bottom:70px;
		}
	}
	.rule{
		p{
			padding-left:0px;
			span{
				&:before{
					content:'';
				}
			}
		}
		ul{
			font-weight:400;
		    line-height: 30px;
		    li{
		    	position:relative;
		    	counter-increment: number;
		    	padding-left:40px;
		    	@include sp{
		    		padding-left:30px;
		    	}
		    	&:before{
		    		position:absolute;
		    		content:'（'counter(number)'）';
		    		left:0;
		    	}
		    }
		}
		span{
			@include f-size(17);
			font-weight:500;
			@include sp{
				@include f-size(13);
			}
		}
	}
}
