/*******************************
Style Start
*******************************/
 // main visual
.mv{
	text-align: center;
	position:relative;
	z-index: 2;
	&-text{
		font-family: $yugo;
		font-weight:bold;
		color:white;
		position:absolute;
		color:#fff;
		text-align: center;
		@include center;
		h2{
			@include f-size(83);
			color:#fff;
		}
		p{
			@include f-size(46);
			color:#fff;
		}
	}
}

//top section
.top{
	//sec plan
	&-plan{
	    padding: 0px 40px;
		margin:125px auto 180px;
	    max-width: 2000px;
	    position:relative;
	    @include sp{
	    	padding:30px 0 0;
	    	margin:0px auto 55px;
	    }
	    .img-wrap{
	    	position: absolute;
	    	width: 1840px;
	    	margin: 0 auto;
	    	left: 50%;
	    	top: 0;
	    	margin-left: -920px;
	    	@include breakpoint(1840px) {
	    		width: 110%;
	    		margin-left: 0px;
	    		transform: translateX(-50%);
	    	}
	    	>div{
	    		position:absolute;
	    		top:0;
	    	}
	    	.img-left{
	    		left:35px;
	    		.thumb{
					&:nth-child(1){
						img:nth-child(2){
							top: -120px;
							right: -100px;
						}
					}
					&:nth-child(2){
						img:nth-child(2){
							top: -60px;
							left: -70px;
						}
					}
	    		}
    		}
	    	.img-right{
				right: 35px;
				.thumb{
					&:nth-child(1){
						img:nth-child(2){
							top: 150px;
							right: -55px;
						}
					}
					&:nth-child(2){
						img:nth-child(2){
					    top: 160px;
					    left: -105px;
						}
					}
				}
	    	}
	    	.thumb{
	    		position:relative;
	    		margin-bottom: 30px;
	    		width: 450px;
	    		@include breakpoint(1840px) {
	    			width: 325px;
	    			@include breakpoint(1290px) {
	    				display: none;
	    			}
	    			@include sp {
	    				display: none;
	    			}
	    		}
	    		img{
    				border-radius: 6px;
    				&:nth-child(2){
    					position:absolute;
    				}
	    		}
	    	}
	    }
	    .container{
	    	position:relative;
	    	text-align: center;
	    	@include breakpoint(1330px) {
	    		width: 45%;
	    	}
	    	@include breakpoint(1290px) {
	    		width: 100%;
	    	}
	    }
		h2{
			@include f-size(32);
			&:before{
				content:"";
				background:url('../img/others/stars.png') no-repeat;
				height:45px;
				width:155px;
				background-size:cover;
				display:block;
				margin:0 auto;
			    margin-bottom: 20px;
			    @include sp{
			    	margin-bottom:10px;
			    }
			}
			@include sp{
				@include f-size(22);
				&:before{
					height:24px;
					width:82px;
				}
			}
		}
		p{
			line-height: 30px;
			@include sp{
			    line-height: 22px;
			}
		}
		a{
		    margin-top: 35px;
		}
		.plan-x{
			margin-top: 25px;
			margin-bottom: 15px;
			@include sp{
			    margin: 12px 0 12px 0;
			}
		}
		.plan-decor{
			img{
				position:absolute;
				&:nth-child(1){
					top:0;
					left:-20px;
				}
			}
		}
	}

	//Section STAFF
	&-staff{
		padding:45px 0 100px;
		margin-bottom:180px;
		position:relative;
		background:url('../img/others/twinkle_double02.png') no-repeat, $color3;
		background-position: 50% 115px;
		.container {
			@include breakpoint(1150px) {
				width: 100%;
			}
		}
		@include breakpoint(1750px) {
			background-image: none;
		}
		@include sp{
		    padding: 30px 0 20px;
	        margin-bottom: 75px;
		}
	}

	//Section VOICE
	&-voice{
		position:relative;
		margin-bottom:170px;
		>div{
			padding:45px 0 100px;
			position:relative;
			@include sp{
				padding:25px 0 20px;
				background:transparent;
				&:after{
					content:'';
					display:block;
					background:$color5;
					position:absolute;
					height:100%;
					width:74%;
					left: 0;
					right:0;
					top: 0;
					margin:0 auto;
					z-index: -1;
				}
			}
		}
		.c-list2{
			width:1100px;
			position:relative;
			left:-50px;
			@include breakpoint(1200px) {
				width:auto;
				left:0;
			}
			.item {
				padding-bottom: 20px;
				@include breakpoint(1200px) {
				p {
					width: 80%;
					margin: 0 auto;
				}
				}
			}
			.thumb {
				@include breakpoint(1200px) {
					border: none;
				    border-radius: 0;
				    overflow: hidden;
				    box-shadow: none;
				    text-align: center;
			    img {
			    	border: 3px solid #fdcd00;
				    border-radius: 5px;
				    overflow: hidden;
				    -webkit-box-shadow: 0 0 12px rgba(35,35,35,0.15);
				    box-shadow: 0 0 12px rgba(35,35,35,0.15);
			    }
				}
			}
			// @include sp{
			// 	width:auto;
			// 	left:0;
			// }
		}
		@include sp{
		    margin-bottom: 75px;
		}
	}

	&-life{
		padding:45px 0px;
		position:relative;
		margin-bottom:180px;
		background:url('../img/others/twinkle_double01.png') no-repeat, $color3;
		background-position: 50% -45px;
		.topSec-btn{
			top:70px;
			@include sp{
				top:25px;
			}
		}
		@include sp{
			padding:30px 0 0;
			margin-bottom:70px;
		}
		.c-list3 {
			max-width: 1000px;
			margin: 0 auto;
			a {
				margin-bottom: 10px;
			}
		}
	}
	&-news{
		padding:45px 0 100px;
		position:relative;
		.c-btn2{
			margin-top:65px;
			@include sp{
				margin-top:20px;
			}
		}
		@include sp{
		    padding: 25px 0 55px;
		}
	}
}

//OTHERS
.l-top{
	.c-title{
		margin-bottom:40px;
		@include sp{
			margin-bottom:15px;
		}
		.en {
			position: relative;
			z-index: 11;
		}
	}
	.quote{
		text-align: center;
		margin-bottom:30px;
		@include sp{
			margin-bottom:15px;
		}
	}
	.mark01{
		img{
			position:absolute;
			top:-32px;
			left:0;
			z-index: 10;
		}
		@include sp{
			display:none;
		}
	}
	.mark02{
		img{
			position:absolute;
			&:nth-child(2){
				bottom: -25px;
				left: -310px;
			}
			&:nth-child(1){
				top: 45px;
				right: -305px;
			}
		}
		@include sp{
			display:none;
		}
	}
}


.topSec-btn{
	position:relative;
	top:125px;
	@include sp{
		top: 35px;
	}
}

.top-line{
	&:before{
	content:"";
	display:block;
	height:65px;
	width:1px;
	background-color: #bfbfbf;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    position:absolute;
    z-index: 5;
    @include sp{
    	height:37px;
    	width:2px;
    }
    }
}

