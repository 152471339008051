@charset "UTF-8";

/*
#overview
base
*/
/*
#colors
@$color1 #1255a3
@$color2 #15449b
@$color3 #bebebe
@$color4 #898989
@$textColor #000
*/
$colorWhite:#fff;
$color1:#f75740;
$color2:#f8cac3;//pink btn
$color3:#fdefed;//pink bg
$color4:#fdcd00;//yellow btn
$color5:#fffbe8;//yellow bg
$color6:#eeeeee;//grey bg
$commonColor:#434343;


// font
$baseFont: 15;
$noto: "Noto Sans Japanese","ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;
$mincho: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", serif;
$yugo: 游ゴシック体, 'Yu Gothic', YuGothic, 'ヒラギノ角ゴシック Pro', 'Hiragino Kaku Gothic Pro', メイリオ, Meiryo, Osaka, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
$Montserrat: 'Montserrat';


$baseWidth: 1000px;
$maxWidth: 2000px;

$bpPcs: 1000px;
$bpTb: 768px;
$bpSp: 640px;
$bpSps: 425px;

$zIndex_default: 1;
$zIndex_below: -1;
$zIndex_header: 2000;
$zIndex_side: 1500;
$zIndex_slider: 1000;
$zIndex_toplayer: 5000;
