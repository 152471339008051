.l-feeling{
	.c-titlePage{
		background-image:url('../img/feeling/ttlPage_bg.jpg');
		@include sp {
			background-image:url('../img/feeling/ttlPage_bg_sp.jpg');
		}
	}

	.feeling{
		&-greeting{
			position:relative;
		    margin-top: 15px;
		    padding-bottom:220px;
		    @include sp{
		    	margin-top:10px;
		    	padding-bottom:50px;
		    }
		    .c-box {
		    	.c-title2 {
		    		@include sp {
		    			padding-top: 20px;
		    		}
		    	}
		    }
		}


		&-reason{
			background:url('../img/others/twinkle_01_light.png') no-repeat, $color3;
	        background-position: 8% -115px;
		    position:relative;
		    padding:80px 0 105px;
		    @include sp{
		    	padding:20px 0;
		    	margin-bottom:30px;
		    }
		    .mark01{
		    	img{
	    		    left: calc(50% - 416px);
    		        top: 35px;
		    	}
		    }
		    h3{
		    	margin-bottom:65px;
		    	position: relative;
		    	z-index: 10;
		    	@include sp{
		    		margin-bottom:20px;
		    	}
		    }
		    .reasonbox-wrapper{
		    	display:flex;
		    	justify-content: space-between;
		    	@include sp{
		    		display:block;
		    	}
		    }
		    .box{
		    	text-align: center;
		    	background:#fff;
		    	min-width:230px;
		    	border-top:6px solid $color2;
		    	padding:22px 0 26px;
		    	box-shadow: 0 0 12px rgba(53,53,53,0.1);
		    	@include sp{
		    		min-width:200px;
		    		padding: 10px 0;
		    		margin-bottom:10px;
		    		box-shadow: 0 0 4px rgba(53,53,53,0.1);
		    		border-width:4px;
		    	}
		    	span{
		    		@include font(over);
		    		@include f-size(60);
		    		color:$color2;
		    		line-height: 1;
		    		&:before{
		    			content:'';
		    			background:url('../img/feeling/reason.png') no-repeat;
		    			background-size:cover;
		    			height:27px;
		    			width:67px;
		    			display:block;
		    			margin:0 auto;
		    		}
		    		@include sp{
		    			@include f-size(40);
		    		}
		    	}
		    	p{
		    		@include f-size(18);
		    		line-height: 28px;
		    		@include sp{
		    			@include f-size(13);
		    			line-height: 22px;
		    		}
		    	}
		    	.c-btn2{
		    		margin-top:20px;
		    		@include sp{
		    			margin-top:5px;
		    		}
		    	}
		    }
		}

		&-salon{
			padding:175px 0 60px 0;
			position:relative;
			@include sp{
				padding:20px 0;
			}
			h3{
				margin-bottom:45px;
				@include sp{
					margin-bottom:20px;
				}
			}
			.container{
				@include sp{
					padding: 0 0;
				}
			}
			.salon-subtext {
				position: relative;
				z-index: 10;
			}
			.salon-gallery{
				position: relative;
				width: 1840px;
				margin: 0 auto;
				left: 50%;
				top: 0px;
				margin-left: -920px;
				margin-top:40px;
				display:flex;
				flex-wrap:wrap;
				justify-content: space-between;
				@include breakpoint(1840px){
					width:100%;
					left:0px;
					top:0px;
					margin-left:0px;
				    img{
				    	width:23%!important;
				    	height:100%;
				    }
				}
				@include sp{
					width:auto;
					margin:0 0;
					left:0;
					margin-top:20px;
					justify-content: space-between;
				}
				img{
					width:438px;
					border-radius: 6px;
				    margin-bottom: 28px;
				    @include sp{
				    	width:48%!important;
				    	height:100%;
				    	margin-bottom:10px;
				    }
				}
			}
			.mark01{
				img{
					top:50px;
				}
			}
			.c-btn2{
				margin-top: 25px;
			}
		}

		&-house{
			padding:70px 0 270px;
			position: relative;
			@include sp{
				padding:30px 0 50px;
			}
			.mark01{
				img{
					right: 215px;
					top: 85px;
					left:unset;
				}
			}
			.c-box{
				.c-title2 {
					@include sp {
						padding-top: 20px;
					}
				}
				a{
					margin-top:35px;
					@include sp{
						margin: 20px auto 0;
					}
				}
			}
		}




	}
	//OTHERS
	.mark01{
		>img{
			position:absolute;
			top:0px;
			left:120px;
			z-index: 10;
		}
		@include sp{
			display:none;
		}
	}

	.mark02{
		>img{
			position:absolute;
			top:280px;
			left:135px;
			z-index: 10;
		}
		@include sp{
			display:none;
		}
	}

	.mark03{
		>img{
			position:absolute;
			top: 180px;
			right: 90px;
		}
		@include sp{
			display:none;
		}
	}

	.mark04{
		>img{
			position:absolute;
		    bottom: 0;
		    left: 175px;
		}
		@include sp{
			display:none;
		}
	}

}