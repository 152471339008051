.l-online{
	&.error {
		.c-form {
			tr {
				.online_not_error {
					color: #434343;
				}
				td:nth-child(2) {
					color: #e60012;
				}
			}
		}
	}
	.container {
		padding: 0 10px;
	}
	.c-titlePage{
		background-image: url('../img/online/ttlPage_bg.jpg');
		@include sp {
			background-image: url('../img/online/ttlPage_bg_sp.jpg');
		}
	}
	.c-breadcrumb{
		padding-bottom:45px;
		@include sp{
			padding-bottom:20px;
		}
	}
	.border-b {
		text-decoration: underline;
		&:hover {
			opacity: 0.75;
		}
	}
	.step{
		text-align: center;
		margin-bottom: 50px;
		@include sp{
			margin-bottom:20px;
		}
		ul{
			margin:0 auto;
			overflow: hidden;
			width:100%;
			display:flex;
			justify-content: space-around;
			max-width: 1000px;
			@include sp{
				width:100%;
				justify-content: center;
			}
			li{
				display:flex;
				text-align: center;
				position: relative;
				color:#b9b9b9;
				padding:25px 0;
				background:#f4f4f4;
				//width:33.33%;
				@include sp{
					align-items: center;
				}
				&:nth-child(1){
					border-radius: 10px 0px 0px 10px;
					padding:25px 50px;
					&:after{
						content: "";
						position: absolute;
						width: 0;
						height: 0;
						top: 0;
						right: -42px;
						border-top: 53px solid transparent;
						border-left: 42px solid #f4f4f4;
						border-bottom: 53px solid transparent;
						z-index: 1;
						@include sp{
							top: 50%;
							margin-top: -40px;
							right: -25px;
							border-top: 40px solid transparent;
							border-left: 25px solid #f4f4f4;
							border-bottom: 40px solid transparent;
						}
						@include breakpoint(385px) {
							border-left: 15px solid #f4f4f4;
							right: -15px;
						}
					}
					@include sp {
						padding: 10px 17px;
					}
					@include breakpoint(385px) {
						padding: 10px 7px 10px 10px;
					}
				}
				&:nth-child(2),
				&:nth-child(3){
					padding:25px 21px;
					&:before{
						content: "";
						position: absolute;
						width: 0;
						height: 0;
						top: 0px;
						left: -42px;
						border-top: 53px solid #f4f4f4;
						border-left: 42px solid #fff;
						border-bottom: 53px solid #f4f4f4;
						z-index: 0;
						@include sp{
							top: 50%;
							left: -20px;
							margin-top: -40px;
							border-top: 40px solid #f4f4f4;
							border-left: 25px solid #fff;
							border-bottom: 40px solid #f4f4f4;
						}
						@include breakpoint(385px) {
							border-left: 15px solid #fff;
							left: -15px;
						}
					}

					&:after{
						content: "";
						position: absolute;
						width: 0;
						height: 0;
						top: 0px;
						right: -42px;
						border-top: 53px solid transparent;
						border-left: 42px solid #f4f4f4;
						border-bottom: 53px solid transparent;
						z-index: 1;
						@include sp{
							top: 50%;
							margin-top: -40px;
							right: -25px;
							border-top: 40px solid transparent;
							border-left: 25px solid #f4f4f4;
							border-bottom: 40px solid transparent;
						}
						@include breakpoint(385px) {
							border-left: 15px solid #f4f4f4;
							right: -15px;
						}
					}
					@include sp {
						padding:10px 17px;
					  margin: 0 5px 0 25px;
					}
					@include breakpoint(385px) {
						padding: 10px 7px 10px 10px;
					  margin: 0 5px 0 25px;
					}
				}
				&:nth-child(2) {
					margin-left:50px;
					@include sp {
						margin-left: 25px;
					}
					@include breakpoint(385px) {
						margin-left: 20px;
					}
				}
				&:nth-child(3) {
					margin-right:50px;
					margin-left:50px;
					@include sp {
						margin-right: 4px;
    				margin-left: 20px;
					}
					@include breakpoint(385px) {
						margin-right: 4px;
    				margin-left: 14px;
					}
				}
				&:nth-child(4){
					border-radius: 0px 10px 10px 0;
					padding: 25px 77px;
					&:before{
						content: "";
						position: absolute;
						width: 0;
						height: 0;
						top: 0;
						left: -42px;
						border-top: 53px solid #f4f4f4;
						border-left: 42px solid #fff;
						border-bottom: 53px solid #f4f4f4;
						z-index: 0;
						@include sp{
							top: 50%;
							left: 0px;
							margin-top: -40px;
							border-top: 40px solid transparent;
							border-left: 25px solid #fff;
							border-bottom: 40px solid transparent;
						}
						@include breakpoint(385px) {
							border-left: 15px solid #fff;
						}
					}
					@include sp {
					    padding: 10px 20px 10px 35px;
					}
					@include breakpoint(385px) {
					    padding: 10px 20px 10px 30px;
					}
				}
				p{
					width:100%;
					span{
						display:block;
						@include f-size(16);
						line-height: 1;
						&.is-pc-hidden {
						  display: none;
						  @include sp {
						    display: block;
						  }
						}

						&.is-sp-hidden {
						  display: block;
						  @include sp {
						    display: none;
						  }
						}
						@include sp{
							@include f-size(12);
						}
					}
					i{
						@include f-size(30);
						margin-bottom:10px;
						line-height: 1;
						@include sp{
							@include f-size(17);
							margin-bottom:5px;
						}
					}
				}
				&.current{
					background:$color2;
					color:#2f2f2f;
					&:before{
						border-top-color:#f8cac3;
						border-bottom-color:#f8cac3;
					}
					&:after{
					    border-left-color: #f8cac3;
					}
				}
			}
		}
	}
	h3{
		@include f-size(30);
		border-bottom:4px solid #dcdcdc;
		padding-bottom:10px;
		margin-bottom: 30px;
		font-weight: 500;
		text-indent: -14px;
    padding-left: 33px;
		i{
			margin-right:10px;
			// vertical-align: middle;
		}
		@include sp{
			@include f-size(20);
			border-width:2px;
			margin-bottom:15px;
			padding-bottom: 5px;
		}
	}
	.c-form{
		margin-top:45px;
		margin-bottom: 20px;
		@include sp{
			margin-top:20px;
		}
	}
	.red {
			color: #e60012;
		}
	.information{
		margin-top: 30px;
		padding: 35px 50px;
		margin-bottom:50px;
		border-radius: 4px;
		background:#f4f4f4;
		span{
			@include f-size(21);
			font-weight: 500;
			@include sp{
				@include f-size(18);
			}
		}
		@include sp {
			margin-top:15px;
			padding:20px 25px;
			margin-bottom:25px;
		}
	}
	.c-btn2{
		margin-bottom:50px;
		@include sp{
			margin-bottom:25px;
			min-width:200px;
		}
		input{
			width:100%;
			height:100%;
			background:transparent;
			color:inherit;
		}
	}
	.confirm-btnWrap{
		display:flex;
		flex-wrap:wrap;
		justify-content: center;
		margin-right:-50px;
		margin:50px -50px 50px 0;
		>a, div{
			margin: 0 50px 0px 0;
			@include sp{
				margin: 0 25px 10px 0;
				min-width: 200px;
			}
		}
		>div{
			input{
				height:100%;
				width:100%;
			}
		}
		@include sp{
			margin:25px -20px 25px 0;

		}
	}
}

.l-online-completed{
	padding-bottom: 60px;
	@include sp {
		padding-bottom: 45px;
	}
	.message{
		text-align: center;
		margin-top:50px;
		span{
			@include f-size(21);
			display:block;
			margin-bottom:20px;
			@include sp{
				@include f-size(18);
				margin-bottom:0px;
			}
		}
		@include sp{
			margin-top:20px;
		}
	}
	.information{
		display:flex;
		align-items: center;
		@include sp{
			flex-direction: column;
			padding: 10px 25px 20px;
			margin-bottom: 35px;
			margin-top: 30px;
		}
		span{
			width:13%;
			@include sp{
				width:35px;
				margin-bottom: 10px;
			}
		}
	}
}

.l-online_reservation {
	.loan-btn-box {
		padding: 40px 0px 60px;
		margin-bottom: 60px;
		@include sp {
			padding: 30px 0px 40px;
			margin-bottom: 25px;
		}

		.c-btn3 {
			max-width: 250px;
			width: 100%;
			margin:  0 auto;
			position: relative;
			border-radius: 7px;
			i {
				position: absolute;
				top: 50%;
				right: 20px;
				transform: translateY(-50%);
			}
		}

	}

	.reservation-notes-list {
		padding-top: 35px;
		padding-bottom: 30px;
		@include sp {
			padding-top: 17px;
 			padding-bottom: 23px;
		}
		li {
			@include f-size(15);
			position: relative;
			text-indent: -29px;
			padding-left: 29px;
			line-height: 1.9em;
			font-weight: 400;
			&::before {
				content: "";
				background: url('../img/others/star.png') no-repeat;
				background-size: cover;
				width: 22px;
				height: 22px;
				display: inline-block;
				vertical-align: sub;
				margin-right: 7px;
			}
		}
	}

	.recommendation {
		background-color: #fff5cc;
		display: block;
		padding: 27px 0px;
		@include sp {
			padding: 20px 15px;
		}
		p {
			@include f-size(21);
			text-align: center;
			@include sp {
				@include f-size(17);
			}
		}
	}

	.information {
		margin-top: 20px;
	}
	.information{
		display:flex;
		align-items: center;
		@include sp{
			flex-direction: column;
			padding: 10px 25px 20px;
			margin-bottom: 35px;
			margin-top: 30px;
		}
		span{
			width:13%;
			@include sp{
				width:35px;
				margin-bottom: 10px;
			}
		}
	}
	.decision-text {
		@include f-size(15);
		padding-bottom: 5px;
		@include sp {
			padding-bottom: 15px;
			border-bottom: 2px solid #434343;
			margin-bottom: 20px;
			&.reverse {
				padding-top: 15px;
				border-top: 2px solid #434343;
				margin-top: 20px;
				padding-bottom: 0px;
				border-bottom: none;
				margin-bottom: 0px;
			}
		}
	}
	.calendar-box {
		border: 2px solid #434343;
		border-radius: 7px;
		padding: 30px;
		margin-bottom: 90px;

		@include sp {
			border: none;
			padding: 0px;
			margin-bottom: 40px;
		}

		.calendar-page {
			@include flex;
			margin-bottom: 25px;

			&.is-pcb-hidden {
			  display: none;
			  @include sp {
			    display: flex;
			  }
			}

			@include sp {
				padding-bottom: 15px;
				border-bottom: 2px solid #c9c9c9;
				&.reverse {
					margin-top: 20px;
					padding-bottom: 0;
					border-bottom: none;
				}
			}

			.month-btn {
				a {
					display: block;
					padding: 2px 27px;
					border-radius: 8px;
					background-color: #434343;
					color: #fff;
					@include f-size(15);
					&:hover {
						opacity: 0.75;
					}

					&[disabled] {
						background-color: #9c9c9c;
						pointer-events: none;
					}
					@include breakpoint(410px) {
						@include f-size(13);
						padding: 2px 20px;
					}
				}
			}

			.month {
				@include f-size(21);
				@include breakpoint(410px) {
						@include f-size(18);
					}
			}
		}
	}

	.yoyakuCal {
		width: 100%;
		border-top: 2px solid #c9c9c9;
		border-left: 2px solid #c9c9c9;
		display: table;
		table-layout: fixed;
		@include sp {
			border-left: none;
			border-bottom: 2px solid #c9c9c9;
		}
		tr {
			th {
				width: calc(100% / 7);
				text-align: center;
				border-right: 2px solid #c9c9c9;
				border-bottom: 2px solid #c9c9c9;
				@include f-size(18);
				@include sp {
					display: none;
				}
			}
			td {
				width: calc(100% / 7);
				text-align: center;
				padding: 15px 5px;
				border-right: 2px solid #c9c9c9;
				border-bottom: 2px solid #c9c9c9;
				line-height: 1.5;
				height: 150px;
				@include sp {
					border: none;
					@include flex(flex-start);
					width: 100%;
					height: auto;
					.sp-date {
						width: 170px;
						text-align: left;
						padding-left: 10px;
					}
					.state {
						width: calc(100% - 170px);
						text-align: left;
					}
				}
				&.sun {
					background-color: #fdefed;
				}
				&.empty {
					@include sp {
						display: none;
					}
				}
				p:first-child {
					@include f-size(20);
					font-family: 'Overpass', sans-serif;
				}
				a {
					line-height: 1.2em;
					&:hover {
						opacity: 0.75;
					}
				}
			}
		}
	}

}